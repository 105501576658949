import {
  IDENTITY_REGISTER_ATTEMPT,
  IDENTITY_REGISTER_FAILURE,
  IDENTITY_REGISTER_SUCCESS,
  IDENTITY_SUBSCRIBE_ATTEMPT,
  IDENTITY_SUBSCRIBE_FAILURE,
  IDENTITY_SUBSCRIBE_SUCCESS,
  CLEAR_SIGNUP_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  errorCode: null,
  attempting: false,
  registerSuccess: null,  
  subscribeSuccess: null,
};

export const IdentityRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case IDENTITY_REGISTER_ATTEMPT:
      return {
        ...state,
        attempting: true,
        errorCode: null,
        registerSuccess: null,
      };

    case IDENTITY_REGISTER_FAILURE:
      return {
        ...state,
        attempting: false,
        registerSuccess: null,
        errorCode: action.errorCode,
      };
    case IDENTITY_REGISTER_SUCCESS:
      return {
        ...state,
        attempting: false,
        registerSuccess: action.registerSuccess,
        errorCode: null,
      };
      case IDENTITY_SUBSCRIBE_ATTEMPT:
        return {
          ...state,
          attempting: true,
          errorCode: null,
          subscribeSuccess: null,
        };
  
      case IDENTITY_SUBSCRIBE_FAILURE:
        return {
          ...state,
          attempting: false,
          subscribeSuccess: null,
          errorCode: action.errorCode,
        };
      case IDENTITY_SUBSCRIBE_SUCCESS:
        return {
          ...state,
          attempting: false,
          subscribeSuccess: action.subscribeSuccess,
          errorCode: null,
        };      
    case CLEAR_SIGNUP_STATE:
      return {
        initialState,
      };
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
