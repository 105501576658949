import React, { useEffect } from 'react';

// mui utils
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

// iangels utils
import { getStartupPageSelector, getStartupPageAttemptingSelector } from '../StartupPage/selectors';
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import CompanyPageHeader from '../StartupPage/CompanyPageHeader';
import { getStartupPageAttempt } from '../StartupPage/actions';
import { getNdaEmbeddedUrlAttempt } from './actions';
import { getEmbeddedUrlSelector } from './selectors';

// third party utils
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const Nda = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const loading = useSelector(getStartupPageAttemptingSelector);
  const startupPage = useSelector(getStartupPageSelector);
  const embeddedUrl = useSelector(getEmbeddedUrlSelector);

  useEffect(() => {
    dispatch(getStartupPageAttempt(params?.slug));
  }, [params, dispatch]);

  useEffect(() => {
    if (!loading && startupPage?.currentRoundId) {
      dispatch(getNdaEmbeddedUrlAttempt(startupPage.currentRoundId));
    }
  }, [params, dispatch, startupPage, loading]);

  return (
    <Container
      style={{
        padding: 0,
        marginTop: 40,
        minHeight: 800,
      }}
    >
      {loading ? (
        <LoadingIndicator />
      ) : !loading && !startupPage ? (
        navigate('/error')
      ) : (
        <>
          <CompanyPageHeader
            logo={startupPage.logo}
            tagLine={startupPage.tagLine}
            sector={startupPage.sector}
            name={startupPage.name}
            investOptions={startupPage.investOptions}
            hideMinimum={startupPage.hideMinimum}
            currentRoundId={startupPage.currentRoundId}
            isFund={startupPage.isFund}
            isUserAccredited={startupPage.isUserAccredited}
            userCannotInvest={startupPage.userCannotInvest}
          />
          <h3>In order to view the supplementary information, please sign the NDA below</h3>
          <Paper elevation={3} style={{ width: '100%', height: '100%', marginTop: 20, borderRadius: 0 }}>
            {embeddedUrl ? (
              <iframe
                src={embeddedUrl.url}
                width="100%"
                height={'100%'}
                frameBorder="0"
                title="docusign"
                style={{ marginBottom: 40 }}
                className="esign"
              ></iframe>
            ) : null}
          </Paper>
        </>
      )}
    </Container>
  );
};

export default Nda;
