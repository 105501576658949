import { createSelector } from 'reselect';

const getRolloverPopupAttempting = (state) => state.rolloverPopup.attempting;
const getRolloverPopupSuggests = (state) => state.rolloverPopup.rolloverSuggests;
const getFilteredRollovers = (state) => state.rolloverPopup.filteredRollovers;
const getFilteredCashBalances = (state) => state.rolloverPopup.filteredCashBalances;
const getSelectedKYC = (state) => state.rolloverPopup.selectedKYC;
const getAvailableSumRollover =(state)=> state.rolloverPopup.availableSumRollover;
const getRolloverInvestmentSuccess = (state)=> state.rolloverPopup.investmentsSuccess;
const getRolloverAttemptingInvest = (state) => state.rolloverPopup.attemptingInvest;
const getSaveRolloverIndex = (state)=> state.rolloverPopup.saveRolloverIndex;
const getRolloversToInvest = (state)=> state.rolloverPopup.rolloversToInvest;
const getOpenPopup = (state)=> state.rolloverPopup.openPopup;
const getErrorInvest = (state)=> state.rolloverPopup.errorInvest;
const getError = (state) => state.rolloverPopup.errorCode;


export const getRolloverPopupAttemptingSelector = createSelector(
  getRolloverPopupAttempting,
  (attempting) => attempting
);

export const getRolloverPopupSuccessSelector = createSelector(
  getRolloverPopupSuggests,
  (rolloverPopupSuggests) => rolloverPopupSuggests
);
export const getFilteredRolloversSelector = createSelector(
  getFilteredRollovers,
  (filteredRollovers) => filteredRollovers
);

export const getFilteredCashBalancesSelector = createSelector(
  getFilteredCashBalances,
  (filteredCashBalances) => filteredCashBalances
);

export const getSelectedKYCSelector = createSelector(
  getSelectedKYC,
  (selectedKYC) => selectedKYC
);
export const getAvailableSumRolloverSelector= createSelector(
  getAvailableSumRollover,
  (availableSumRollover) =>availableSumRollover
);
export const getSumEditableSelector= createSelector(getSelectedKYC,(selectedKYC) =>{
  return selectedKYC === 'All'? false: true;
});

export const getRolloverInvestmentSuccessSelector= createSelector(
  getRolloverInvestmentSuccess,
  (rolloverInvestmentSuccess) =>rolloverInvestmentSuccess
);
export const getRolloverAttemptingInvestSelector= createSelector(
  getRolloverAttemptingInvest,
  (attemptingInvest) => attemptingInvest
);
export const getSaveRolloverIndexSelector= createSelector(getSaveRolloverIndex, (saveRolloverIndex) =>saveRolloverIndex);
export const getRolloversToInvestSelector= createSelector(getRolloversToInvest, (rolloversToInvest) =>rolloversToInvest);

export const getOpenPopupSelector= createSelector(getOpenPopup, (openPopup) => openPopup);

export const getErrorInvestSelector = createSelector(getErrorInvest, (errorInvest) => errorInvest);


export const getErrorSelector = createSelector(getError, (errorCode) => errorCode);

export const getRolloverEntitiesSelector = createSelector(getRolloverPopupSuggests, (rolloverPopupSuggests) => {
  if (!rolloverPopupSuggests) return [];
  const entities=Array.from(
    new Set(rolloverPopupSuggests?.map((item) => item.entity))
  );
  return entities?.length > 1 ? ["All", ...entities] : entities;
});

export const getTotalInvestmentsSelector = createSelector(getFilteredRollovers,(filteredRollovers) => {
    if (!filteredRollovers) return 0;
    return filteredRollovers.reduce((total, rollover) => total + (rollover.amountToInvest || 0), 0);
  });


export const getTotalCashBalancesSelector = createSelector(getFilteredCashBalances,(filteredCashBalances) => {
    if (!filteredCashBalances) return 0;
    return filteredCashBalances.reduce((total, cash) => total + (cash.amount || 0), 0);
  });

  export const getRolloverPopupTotalsSelector = createSelector(
    [
      getTotalInvestmentsSelector,
      getAvailableSumRolloverSelector,
      getTotalCashBalancesSelector,
    ],
    (totalInvestments, availableSumRollover, totalCashBalances) => {
      let sumRollover = 0;
      let sumCashBalance = 0;
      let sumOther = 0;
      sumRollover = Math.min(totalInvestments, availableSumRollover);
      let remaining = totalInvestments > availableSumRollover ? totalInvestments - availableSumRollover : 0;
  
      sumCashBalance = Math.min(remaining, totalCashBalances);
      remaining = remaining > totalCashBalances ? remaining - totalCashBalances : 0;
  
      sumOther = remaining;
  
      return {
        sumRollover: sumRollover || 0,
        sumCashBalance: sumCashBalance || 0,
        sumOther: sumOther || 0,
      };
    }
  );
