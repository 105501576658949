import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// mui utils
import Stack from '@mui/material/Stack';

// iangels utils
import {
  getSectorsAttemptingSelector,
  getSectorsSelector,
  getPrimarySectorsAttemptingSelector,
  getPrimarySectorsSelector,
} from './selectors';
import { getSectorsAttempt, getPrimarySectorsAttempt } from './actions';
import FounderWrapper from '../../components/FounderPage/FounderWrapper';
import TwoFieldStack from '../../components/FounderPage/TwoFieldStack';
import Text from '../../components/text';
import Field from '../../components/FounderPage/Field';
import { businessModels, businessStages } from '../../helpers/constants';
import countries from '../../components/SharedData/countries.json';
//import primarySectors from '../../components/SharedData/primarySectors.json';
//import subSectors from '../../components/SharedData/subSectors.json';

const CompanyOverview = ({ companyOverview, companyOverviewError, handleCompanyOverviewChange, matches, title }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSectorsAttempt());
    dispatch(getPrimarySectorsAttempt());
  }, [dispatch]);
  // selectors
  const loading = useSelector(getSectorsAttemptingSelector);
  const loadingPS = useSelector(getPrimarySectorsAttemptingSelector);
  const primarySectors = useSelector(getPrimarySectorsSelector);
  const subSectors = useSelector(getSectorsSelector);

  const nameField = {
    type: 'text',
    required: true,
    error: companyOverviewError.name,
    id: 'name',
    label: 'Company Name',
    value: companyOverview.name,
    name: 'name',
    onChange: handleCompanyOverviewChange,
    helperText: companyOverviewError.name,
  };

  const dateFoundedField = {
    type: 'date',
    required: true,
    error: companyOverviewError.dateFounded,
    id: 'dateFounded',
    label: 'Founding Date *',
    value: companyOverview.dateFounded,
    name: 'dateFounded',
    onChange: handleCompanyOverviewChange,
    helperText: companyOverviewError.dateFounded,
  };

  const cityField = {
    type: 'text',
    required: true,
    error: companyOverviewError.city,
    id: 'city',
    label: 'City',
    value: companyOverview.city,
    name: 'city',
    onChange: handleCompanyOverviewChange,
    helperText: companyOverviewError.city,
  };

  const countryField = {
    type: 'select-file-import',
    required: true,
    error: companyOverviewError.country,
    id: 'country',
    label: 'Country *',
    labelId: 'countryLabel',
    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
    value: companyOverview.country,
    itemsList: countries,
    name: 'country',
    onChange: handleCompanyOverviewChange,
    helperText: companyOverviewError.country,
  };

  const websiteField = {
    type: 'text',
    required: false,
    error: companyOverviewError.website,
    id: 'website',
    label: 'Website',
    value: companyOverview.website,
    name: 'website',
    onChange: handleCompanyOverviewChange,
    helperText: companyOverviewError.website,
  };

  const oneLinePitchField = {
    type: 'text',
    required: true,
    error: companyOverviewError.oneLinePitch,
    id: 'oneLinePitch',
    label: 'One Line Pitch',
    value: companyOverview.oneLinePitch,
    name: 'oneLinePitch',
    onChange: handleCompanyOverviewChange,
    helperText: companyOverviewError.oneLinePitch,
  };

  const primarySectorField = {
    type: 'select-file-import',
    required: true,
    error: companyOverviewError.primarySector,
    id: 'primarySector',
    label: 'Primary Sector *',
    labelId: 'PrimarySectorLabel',
    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
    value: companyOverview.primarySector,
    itemsList: primarySectors,
    name: 'primarySector',
    onChange: handleCompanyOverviewChange,
    helperText: companyOverviewError.primarySector,
  };

  const subSectorField = {
    type: 'select-file-import',
    required: true,
    error: companyOverviewError.subSector,
    id: 'subSector',
    label: 'Sub Sector *',
    labelId: 'SubSectorLabel',
    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
    value: companyOverview.subSector,
    itemsList: subSectors,
    name: 'subSector',
    onChange: handleCompanyOverviewChange,
    helperText: companyOverviewError.subSector,
  };

  const businessModelField = {
    type: 'select',
    required: false,
    error: companyOverviewError.businessModel,
    id: 'businessModel',
    label: 'Business Model',
    labelId: 'BusinessModelLabel',
    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
    value: companyOverview.businessModel,
    itemsList: businessModels,
    name: 'businessModel',
    onChange: handleCompanyOverviewChange,
    helperText: companyOverviewError.businessModel,
  };

  const businessStageField = {
    type: 'select',
    required: false,
    error: companyOverviewError.businessStage,
    id: 'businessStage',
    label: 'Business Stage',
    labelId: 'BusinessStageLabel',
    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
    value: companyOverview.businessStage,
    itemsList: businessStages,
    name: 'businessStage',
    onChange: handleCompanyOverviewChange,
    helperText: companyOverviewError.businessStage,
  };

  return (
    <FounderWrapper matches={matches}>
      <Text variant="h6" classes="f-tgf-400 g4-c" text={title} />
      <TwoFieldStack fieldAObject={nameField} fieldBObject={dateFoundedField} />
      <TwoFieldStack fieldAObject={cityField} fieldBObject={countryField} />
      <Stack direction="column" style={{ width: '100%' }}>
        <Field obj={websiteField} />
        <Field obj={oneLinePitchField} />
      </Stack>
      <TwoFieldStack
        fieldAObject={loadingPS ? null : primarySectorField}
        fieldBObject={loading ? null : subSectorField}
      />
      <TwoFieldStack
        fieldAObject={businessModelField} 
        fieldBObject={businessStageField} 
      />
    </FounderWrapper>
  );
};

export default CompanyOverview;
