import React, { useEffect } from 'react';

// mui utils
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

// iangels utils
import { getSigningUrlSelector, getInvestmentAttemptingSelector } from './selectors';
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';
import { getSigningEmbeddedUrlAttempt } from './actions';
import { openInNewTab } from '../../helpers/iangels';

// third party utils
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const InvestESign = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const loading = useSelector(getInvestmentAttemptingSelector);
  const success = useSelector(getSigningUrlSelector);

  const isMobile = IsMobileDevice();

  useEffect(() => {
    if(!loading && !success)
      dispatch(getSigningEmbeddedUrlAttempt({ investmentType: params?.investmentType, investmentId: params?.investmentId ,isRollover:params?.isRollover }));
  }, [dispatch, params,loading,success]);

  // useEffect(() => {
  //   if (isMobile && success?.embeddedDocumentUrl) {
  //     window.location.replace(success?.embeddedDocumentUrl);
  //   }
  // }, [isMobile, success?.embeddedDocumentUrl]);
  return (
    <Container
      style={{
        padding: 0,
        marginTop: 20,
        minHeight: 800,
      }}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="f-gl-400">
            Thank you for your interest in <span className="g-c bold"> {success?.startupName}.</span>{' '}
            {isMobile ? (
              <>
                <b>Please scroll down and press the 'continue' yellow button </b>to sign the subscription agreement
              </>
            ) : (
              <>To validate your commitment, please sign the subscription agreement below which holds the terms</>
            )}{' '}
            for this specific investment. To download the full limited partnership agreement, please click
            <span
              onClick={() => openInNewTab('https://iangels-res.cloudinary.com/image/upload/v1563702953/LPA_pw9vke.pdf')}
              className="g-c bold pointer"
            >
              {' '}
              here
            </span>
          </div>
          <Paper elevation={3} style={{ width: '100%', height: '100%', marginTop: 20, borderRadius: 0 }}>
            {success?.embeddedDocumentUrl ? (
              <iframe
                style={{ marginBottom: 40 }}
                className="esign"
                src={success.embeddedDocumentUrl}
                width="100%"
                height={'100%'}
                frameBorder="0"
                title="Please Review & Act on These Documents"
              ></iframe>
            ) : null}
          </Paper>
        </>
      )}
    </Container>
  );
};

export default InvestESign;
