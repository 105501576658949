import React, { useCallback, useState } from 'react';
import moment from 'moment';

//mui utils
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// iangels utils
import CapitalPosition from '../../components/CapitalPosition';
import MemoCompanyUpdate from './MemoCompanyUpdate';
import Text from '../text';

const MemoCompanySnapshotMobile = ({
  keyBusinessUpdates,
  monthlyNetBurnRate,
  operatingCashFlow,
  snapshotIsVisible,
  isUserVerified,
  cashOnHand,
  updates,
}) => {
  // state
  const [keyBusinessUpdatesCount, setKeyBusinessUpdatesCount] = useState(1);

  const hasSnapshot = () => {
    return keyBusinessUpdates?.length > 0 && snapshotIsVisible && isUserVerified;
  };

  const hasUpdates = () => {
    return updates?.length > 0 && !snapshotIsVisible;
  };

  const showMore = useCallback(() => {
    setKeyBusinessUpdatesCount(keyBusinessUpdatesCount + 3);
  }, [keyBusinessUpdatesCount]);

  const showMoreButton = () => {
    return keyBusinessUpdates?.length > keyBusinessUpdatesCount;
  };
  const hasCapitalPosition = () => {
    return cashOnHand || monthlyNetBurnRate || operatingCashFlow;
  };

  return (
    <>
      {hasSnapshot() ? (
        <>
          {/*  Capital Position*/}
          {hasCapitalPosition() ? (
            <>
              <CapitalPosition
                cashOnHand={cashOnHand}
                monthlyNetBurnRate={monthlyNetBurnRate}
                operatingCashFlow={operatingCashFlow}
                isMobile={true}
              />

              <Divider className="mt-20" />
            </>
          ) : null}

          {keyBusinessUpdates?.length > 0 ? (
            <>
              <Text classes="f-f-tgf f-w-500 g7-c f-size-16 mt-20 mb-20" text="Key business updates" />
              {keyBusinessUpdates.map((businessUpdates, index) =>
                index < keyBusinessUpdatesCount ? (
                  <Grow in={true} style={{ transformOrigin: '0 0 0' }} key={index} className="pb-10">
                    <Grid container spacing={0} key={index} className="">
                      <Grid item xs={2} className="nowrap f-f-gl f-w-700 f-size-12">
                        ({moment(businessUpdates?.date).format('DD MMM YYYY')})
                      </Grid>
                      <Grid>
                        <div className="content-box memo-formatting">
                          <p>{businessUpdates?.text}</p>
                        </div>
                      </Grid>
                    </Grid>
                  </Grow>
                ) : null
              )}
              {showMoreButton() ? (
                <Button endIcon={<KeyboardArrowDownIcon />} onClick={showMore} className="btn-outlined italic">
                  Read More
                </Button>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
      {hasUpdates() ? (
        <>
          {updates.map((update, index) => (
            <MemoCompanyUpdate title={update.title} content={update.content} isFirst={index === 0} key={index} />
          ))}
        </>
      ) : null}
    </>
  );
};

export default MemoCompanySnapshotMobile;
