import { rolloverPopupFailure, rolloverPopupSuccess, rolloverInvestmentsSuccess,rolloverInvestmentsFailure } from './actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getDataFromService,postData } from '../../services/apiGateway';
import { ROLLOVER_POPUP_ATTEMPT, ROLLOVER_INVESTMENTS_ATTEMPT} from './actionTypes';


function* rolloverSuggestsAttempt(action) {
  try {
    const rolloverSuggests = yield call(getDataFromService, '/iangels/investments/rollover/get_investments');
    yield put(rolloverPopupSuccess(rolloverSuggests?.data));
  } catch (errorCode) {
    yield put(rolloverPopupFailure(errorCode.response || errorCode));
  }
}
function* rolloverInvestmentsAttempt(action) {
  try {
     const data = {
        //currency: params?.currency || null,
        investmentAmount: action.rolloverPopup.amountToInvest,
        name: action.rolloverPopup.entity        ,
        roundId: action.rolloverPopup.round,
        status: action.rolloverPopup.status,
        InvestmentId: action.rolloverPopup.investmentId,
        rolloverInvestmentId: action.rolloverPopup.rolloverInvestmentId,
        installmentId: action.rolloverPopup.installmentId,
        amountRollovered: action.rolloverPopup.amountRollovered,
        amountFromCashBalances: action.rolloverPopup.amountFromCashBalances,
        amountToAdd: action.rolloverPopup.amountToAdd, 

      };
    const update = yield call(postData, '/iangels/investments/rollover/investment',data);
    yield put(rolloverInvestmentsSuccess(update?.data));
  } catch (errorCode) {
    console.log(errorCode);
    yield put(rolloverInvestmentsFailure(errorCode.response || errorCode));
  }
}

function* rolloverPopupSaga() {
  yield all([takeLatest(ROLLOVER_POPUP_ATTEMPT, rolloverSuggestsAttempt)]);
  yield all([takeLatest(ROLLOVER_INVESTMENTS_ATTEMPT, rolloverInvestmentsAttempt)]);
}

export default rolloverPopupSaga;
