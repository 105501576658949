import React from 'react';

// iangels utils
import FounderWrapper from '../../components/FounderPage/FounderWrapper';
import Text from '../../components/text';
import Field from '../../components/FounderPage/Field';

const Traction = ({ traction, tractionError, handleTractionChange, matches, title }) => {
  const tractionField = {
    type: 'text-multiline',
    required: true,
    error: tractionError.traction,
    id: 'traction',
    label: 'Please share your traction to date',
    value: traction.traction,
    name: 'traction',
    onChange: handleTractionChange,
    helperText: tractionError.traction,
  };

  return (
    <FounderWrapper matches={matches}>
      <Text variant="h6" classes="f-tgf-400 g4-c" text={title} />
      <Field obj={tractionField} />
    </FounderWrapper>
  );
};

export default Traction;
