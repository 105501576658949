import { 
  ROLLOVER_POPUP_ATTEMPT,
  ROLLOVER_POPUP_SUCCESS,
  ROLLOVER_POPUP_FAILURE, 
  SET_SELECTED_KYC,
  UPDATE_ROLLOVER_VALUE,
  UPDATE_ALL_ROLLOVERS,
  SET_ROLLOVERS_TO_INVEST,
  ROLLOVER_INVESTMENTS_ATTEMPT,
  ROLLOVER_INVESTMENTS_SUCCESS,
  ROLLOVER_INVESTMENTS_FAILURE,
  CLEAR_ROLLOVER_TO_INVEST,
  SET_POPUP,
  CLEAR_STATE 
} from './actionTypes';

export const rolloverPopupAttempt = (data) => ({
  type: ROLLOVER_POPUP_ATTEMPT,
  data,
});

export const rolloverPopupSuccess = (rolloverSuggests) => ({
  type: ROLLOVER_POPUP_SUCCESS,
  rolloverSuggests,
});

export const rolloverPopupFailure = (errorCode) => ({
  type: ROLLOVER_POPUP_FAILURE,
  errorCode,
});
export const setSelectedKYC = (kyc) => ({
  type: SET_SELECTED_KYC,
  kyc,
});

export const updateRolloverValue = (id, newValue) => ({
  type: UPDATE_ROLLOVER_VALUE,
  id,
  newValue,
});

export const updateAllRollovers = (newTotal) => ({
  type: UPDATE_ALL_ROLLOVERS,
  newTotal,
});

export const setRolloversToInvest= (ArrayTOInvest) => ({
  type: SET_ROLLOVERS_TO_INVEST,
  ArrayTOInvest,
});

export const rolloverInvestmentsAttempt = (rolloverPopup) => ({
  type: ROLLOVER_INVESTMENTS_ATTEMPT,
  rolloverPopup,
});

export const rolloverInvestmentsSuccess = (investmentSuccess) => ({
  type: ROLLOVER_INVESTMENTS_SUCCESS,
  investmentSuccess,
});

export const rolloverInvestmentsFailure = (errorCode) => ({
  type: ROLLOVER_INVESTMENTS_FAILURE,
  errorCode,
});

export const clearRolloverToInvest =() =>({
  type: CLEAR_ROLLOVER_TO_INVEST,
});

export const setPopup = (openPopup) => ({
  type: SET_POPUP,
  openPopup
})

export const clearState = () => ({
  type: CLEAR_STATE,
});
