import { React, useRef,useState, useEffect } from 'react';
// third party utils
import { useSelector, useDispatch } from 'react-redux';

import { OutlinedInput, Grid, InputAdornment, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Tooltip from '@mui/material/Tooltip';

import IsMobileDevice from '../../components/Generic/IsMobileDevice';

// iAngels utils
import { getCommaSeparatedString } from '../../helpers/iangels';
import {
  getTotalInvestmentsSelector,
  getSumEditableSelector,
  getAvailableSumRolloverSelector,
} from '../../containers/RolloverPopup/selectors';
import { updateAllRollovers } from '../../containers/RolloverPopup/actions';

const BalanceSection = ({ cashBalances }) => {
  const dispatch = useDispatch();
  const isMobile = IsMobileDevice();
  const totalInvestments = useSelector(getTotalInvestmentsSelector);
  const sumEditable = !useSelector(getSumEditableSelector);
  const availableSumRollover = useSelector(getAvailableSumRolloverSelector);
  const inputRef = useRef(null); // Create a reference for the input

  const handleEditClick = () => {
    const length = inputRef.current.value.length; // Get text length
    inputRef.current?.focus();
    inputRef.current.setSelectionRange(length, length); // Move cursor to the end
  };
  const handleChange = (event) => {
    let value = event.target.value.replace(/,/g, ''); // Remove commas before processing
    if (isNaN(value) || value === '') {
      value = 0;
    }
    dispatch(updateAllRollovers(Number(value)));
  };
  const title = sumEditable ? (
    <span className="popup-tooltip">
      Please select a KYC above
      <br />in order to edit this field
    </span>
  ) : totalInvestments < availableSumRollover ? (
    <>
      <span className="popup-tooltip">
      You have  <span className="f-popup-sum">{'$ ' + getCommaSeparatedString(availableSumRollover)} </span> in investable funds.
      <br /> Please note you are currently using only <br /> a portion of these funds.
      </span>
    </>
  ) : null;
  const [openTooltip, setOpenTooltip] = useState(false);
  const timeoutRef = useRef(null); // Store timeout reference

  const handleTooltipToggle = (event) => {
    event.stopPropagation(); // Prevents click event from bubbling
    setOpenTooltip((prev) => {
      if (!prev) {
        // If opening, start a 3-second timeout to close
        timeoutRef.current =setTimeout(() => {
          setOpenTooltip(false);
        }, 10000);
      }
      return !prev; // Toggle state
    });

  };
  // Close tooltip when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openTooltip) {
        setOpenTooltip(false);
        clearTimeout(timeoutRef.current); // Cancel timeout if user closes manually
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
}, [openTooltip]);

  return (
    <Grid container width="inherit" direction={isMobile ? 'column' : 'row'}>
      {/* Reinvestment Balance */}
      <Grid item xs={12} md={6}>
        <Typography className="f-popup-headers">Reinvestment balance:</Typography>
      </Grid>

      {/* Input and Cash Balance Section */}
      <Grid item xs={12} md={6}>
        <Grid container spacing={1} direction="column">
          {/* Input Section */}
          <Grid item className="w-c">
            <OutlinedInput
              id="outlined-adornment-sum"
              inputRef={inputRef}
              startAdornment={
                <InputAdornment position="start">
                  <AttachMoneyIcon className={sumEditable ? 'gray-500' : 'g3-c'} />{' '}
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end" className="w-c">
                  {title ? (
                    <Tooltip
                      title={title}
                      arrow
                      placement="top-end"
                      PopperProps={{
                        sx: { '.MuiTooltip-tooltip': { bgcolor: '#F5F5F0', marginBottom: '15px !important' } ,
                        '.MuiTooltip-arrow': { color: '#F5F5F0' }},
                      }}
                      open={openTooltip}
                      disableHoverListener // Disable hover so it only works on click
                      disableFocusListener
                    >
                    <InfoOutlinedIcon className={sumEditable?"gray-500 pointer" :"pointer "}  onClick={handleTooltipToggle}/>
                    </Tooltip>
                  ) : null}
                  {sumEditable ? null : <EditOutlinedIcon className="pointer" onClick={handleEditClick} />}
                </InputAdornment>
              }
              className="w-c f-f-gl f-w-500 f-size-14 lh-18"
              value={getCommaSeparatedString(parseFloat(totalInvestments.toFixed(2)))}
              onChange={handleChange}
              fullWidth
              disabled={sumEditable}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white', // Default white border
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white', // White border on hover
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white', // White border when focused
                },
                '& .MuiInputAdornment-root': {
                  color: 'white', // Optional: Ensures the adornments are also white
                },
                color: 'white', // Text color inside the input
                '&.Mui-disabled': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#F5F5F0', // Border color for disabled state
                  },
                  backgroundColor: '#E1E1E1', // Background color for disabled state
                  color: '#8E8E8E', // Text color for disabled state
                  '& .MuiInputAdornment-root': {
                    color: '#8E8E8E', // Adornment color for disabled state
                  },
                },
                height: '90%', // Make the input field 80% of its default height
                '& .MuiOutlinedInput-input': {
                  padding: '8px 12px', // Adjust padding if needed
                  height: '90%', // Reduce inner height to match the outer shrink
                  fontWeight: 'bold!important',
                },
              }}
            />
          </Grid>

          {/* Cash Balance Section */}
          {cashBalances && cashBalances.length > 0 ? (
            <Grid item>
              <Grid container direction={isMobile ? 'column' : 'row'}>
                {/* Cash Balance Label */}
                <Grid item xs={4}>
                  <Typography className="f-popup-content f-w-700">*Cash balance:</Typography>
                </Grid>

                {/* Cash Balance Grid */}
                <Grid item xs={8}>
                  <Grid container direction="column">
                    {cashBalances?.map((startup, index) => (
                      <Grid container key={index} direction="row">
                        <Grid item xs={8}>
                          <Typography className="f-popup-content">{startup.startupName}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'right' }}>
                          <Typography className="f-popup-content">
                            {'$ ' + getCommaSeparatedString(startup.amount)}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid sx={{ paddingBottom: 2 }}></Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BalanceSection;
