export const ROLLOVER_POPUP_ATTEMPT = 'ROLLOVER_POPUP_ATTEMPT';
export const ROLLOVER_POPUP_SUCCESS = 'ROLLOVER_POPUP_SUCCESS';
export const ROLLOVER_POPUP_FAILURE = 'ROLLOVER_POPUP_FAILURE';
export const SET_SELECTED_KYC='SET_SELECTED_KYC';
export const UPDATE_ROLLOVER_VALUE='UPDATE_ROLLOVER_VALUE';
export const UPDATE_ALL_ROLLOVERS='UPDATE_ALL_ROLLOVERS';
export const SET_ROLLOVERS_TO_INVEST='SET_ROLLOVERS_TO_INVEST';
export const ROLLOVER_INVESTMENTS_ATTEMPT='ROLLOVER_INVESTMENTS_ATTEMPT';
export const ROLLOVER_INVESTMENTS_SUCCESS='ROLLOVER_INVESTMENTS_SUCCESS';
export const ROLLOVER_INVESTMENTS_FAILURE='ROLLOVER_INVESTMENTS_FAILURE';
export const CLEAR_ROLLOVER_TO_INVEST='CLEAR_ROLLOVER_TO_INVEST';
export const SET_POPUP='SET_POPUP';

export const CLEAR_STATE = 'CLEAR_STATE';
