import {
  ROLLOVER_POPUP_ATTEMPT,
  ROLLOVER_POPUP_SUCCESS,
  ROLLOVER_POPUP_FAILURE,
  SET_SELECTED_KYC,
  UPDATE_ROLLOVER_VALUE,
  UPDATE_ALL_ROLLOVERS,
  SET_ROLLOVERS_TO_INVEST,
  CLEAR_STATE,
  ROLLOVER_INVESTMENTS_ATTEMPT,
  ROLLOVER_INVESTMENTS_SUCCESS,
  ROLLOVER_INVESTMENTS_FAILURE,
  CLEAR_ROLLOVER_TO_INVEST,
  SET_POPUP,
} from './actionTypes';

import {calculateUpdatedRollovers} from '../../helpers/iangels';


export const initialState = {
  errorCode: null,
  attempting: false,
  rolloverSuggests: null,
  selectedKYC: "",
  filteredRollovers: null, // Filtered rollovers based on selected KYC
  filteredCashBalances: null, // Filtered CashBalances based on selected KYC
  availableSumRollover: null,
  investmentsSuccess:null,
  attemptingInvest: null,
  errorInvest: null,
  saveRolloverIndex:0,
  rolloversToInvest:null,
  openPopup: true,
};

export const RolloverPopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROLLOVER_POPUP_ATTEMPT:
      return {
        ...state,
        attempting: true,
        errorCode: null,
        rolloverSuggests: null,
        openPopup: true,
      };

    case ROLLOVER_POPUP_FAILURE:
      return {
        ...state,
        attempting: false,
        rolloverSuggests: null,
        errorCode: action.errorCode,
      };
    case ROLLOVER_POPUP_SUCCESS:
      return {
        ...state,
        attempting: false,
        rolloverSuggests: action.rolloverSuggests,
        errorCode: null,
        selectedKYC: "",
        filteredRollovers: null, // Filtered rollovers based on selected KYC
        filteredCashBalances: null, // Filtered CashBalances based on selected KYC
        availableSumRollover: null,

      };
    case SET_SELECTED_KYC:
      const startupMap = new Map(); // To track unique startups by ID
      const cashStartupMap = new Map(); // To track unique startups by ID
      let entitiesToProcess = [];
      if (action.kyc === 'All') {
        entitiesToProcess = state.rolloverSuggests;
      } else {
        const filteredEntity = state.rolloverSuggests.filter((rollover) => rollover.entity === action.kyc);
        // Extract the first object if available
        entitiesToProcess = filteredEntity.length > 0 ? [filteredEntity[0]] : [];
      }

      entitiesToProcess.forEach((entity) => {
        entity.rollovers?.forEach((roll) => {
          if (startupMap.has(roll.startup.id)) {
            // If the startup is already in the map, accumulate the amount
            const existing = startupMap.get(roll.startup.id);
            existing.amountWithFees += Math.round(roll.amountWithFees);
            existing.amountToInvest = Math.round(existing.amountWithFees);
          } else {
            // If the startup is new, add it to the map
            const amountWithFees = Math.round(roll.amountWithFees);
            startupMap.set(roll.startup.id, {
              ...roll.startup,
              amountWithFees,
              amountToInvest: amountWithFees,
              round: roll.round,
              status: roll.status,
              investmentId: roll.id,
              rolloverInvestmentId: roll.rolloverInvestmentId,
              installmentId: roll.installmentId,
              entity: entity.entity
            });
          }
        });
        entity.cashBalances?.forEach((cash) => {
          if (cashStartupMap.has(cash.startupId)) {
            // If the startup is already in the map, accumulate the amount
            const existing = cashStartupMap.get(cash.startupId);
            existing.amount += Math.round(cash.amount);
          } else {
            // If the startup is new, add it to the map
            cashStartupMap.set(cash.startupId, {
              startupName: cash.startupName,
              startupId: cash.startupId,
              cashId: cash.cashId,
              amount: Math.round(cash.amount),
              installmentId:cash.installmentId,
              secondarySaleId:cash.secondarySaleId
            });
          }
        });
      });
      const mergedRollovers = Array.from(startupMap.values());
      const mergedCashBalances = Array.from(cashStartupMap.values());
      const availableSumRollover = mergedRollovers.reduce(
        (total, rollover) => total + (rollover.amountWithFees || 0),
        0
      );
      const cashTotal = mergedCashBalances.reduce((total, cashBalance) => total + (cashBalance.amount || 0), 0);
      //add the cash balance to all rollover suggestes
      const finalRollovers = calculateUpdatedRollovers(mergedRollovers, availableSumRollover + cashTotal);
      return {
        ...state,
        selectedKYC: action.kyc,
        filteredRollovers: finalRollovers,
        filteredCashBalances: mergedCashBalances,
        availableSumRollover: availableSumRollover,
        saveRolloverIndex:0,
        rolloversToInvest: null,
      };
    case UPDATE_ROLLOVER_VALUE:
      return {
        ...state,
        filteredRollovers: state.filteredRollovers.map((rollover) =>
          rollover.id === action.id ? { ...rollover, amountToInvest: action.newValue } : rollover
        ),
      };

    case UPDATE_ALL_ROLLOVERS:
      const updatedRollovers = calculateUpdatedRollovers(state.filteredRollovers, action.newTotal);
      return {
        ...state,
        filteredRollovers: updatedRollovers,
      };
    case SET_ROLLOVERS_TO_INVEST:
      return {
        ...state,
        rolloversToInvest: action.ArrayTOInvest,
      };
    case ROLLOVER_INVESTMENTS_ATTEMPT:
      return {
        ...state,
        investmentsSuccess: null,
        attemptingInvest: true,
        errorInvest: null,
      };
    case ROLLOVER_INVESTMENTS_FAILURE:
      return {
        ...state,
        investmentsSuccess: null,
        errorInvest: action.errorCode,
        attemptingInvest: false,
      };
    case  ROLLOVER_INVESTMENTS_SUCCESS:
      return {
        ...state,
        investmentsSuccess: action.investmentSuccess,
        errorInvest: null,
        attemptingInvest: false,
        saveRolloverIndex: state.saveRolloverIndex+1,
      };
    case CLEAR_ROLLOVER_TO_INVEST:
      return{
        ...state,
        saveRolloverIndex:0,
        rolloversToInvest: null,
        investmentsSuccess: null,
      };

    case SET_POPUP:
        return{
          ...state,
          openPopup: action.openPopup
    };
  
    case CLEAR_STATE:
      return {
        initialState,
      };
    default:
      return state;
  }
};
