export const HEAD_TITLES = {
  Active: ['Company', 'Description', 'Pricing Terms', 'Amount Invested', 'Last Value*', 'Change**', 'Date'],
  Realized: [
    'Company',
    'Event Type',
    'Event Description',
    'Amount Invested',
    'Amount Realized*',
    'Return/Loss**',
    'Date',
  ],
  PublicHoldings: [
    'Company',
    'Previous Close*',
    'Shares holdings',
    'Amount Invested',
    'Last Value**',
    'Change',
    'Date',
  ],
};

export const MOBILE_HEAD_TITLES = ['Company', 'Amount Invested', 'Last Value', 'Change'];

//if change the value here please attention to change also on universal login screen on auth0
export const MOBILE_WIDTH_768 = '(max-width:768px)';
export const MOBILE_HEIGHT_768 = '(max-height:900px)';
export const MOBILE_WIDTH_460 = '(max-width:460px)';
export const MOBILE_WIDTH_499 = '(max-width:499px)';

export const MINUTE_IN_MILLISECOND = 60000;
export const HOUR_IN_MILLISECOND = 3600000;
export const FILES_TYPES = ['JPG', 'PNG', 'GIF'];
export const PREVIEWERS = ['mor@iangels.com', 'mor.assia@gmail.com', 'shelly@iangels.com'];
export const EMAIL_MAX_LENGTH = 254;
export const TEXT_MAX_LENGTH = 254;

export const CLIENTS_NAME = {
  iAngels: 'iangels',
};

export const INVESTMENTS_SIDE_NAV = {
  myInvestments: 'My Investments',
  companySnapshot: 'Company Snapshot',
  founders: "Founders' Bio",
  notableShareholders: 'Notable Shareholders',
  additionalMaterials: 'Additional Materials',
  nda: 'Funding Overview, Cap Table and Potential Returns Analysis',
  description: 'Description',
  portfolio: 'Portfolio',
  PreviousInvestmentThesis: 'Previous Investment Thesis',
};

export const CURRENCY_FORMAT = {
  usd: 'US$',
  usdt: 'USD₮',
  dollar: 'US$',
  eth: 'ETH',
};

export const ROWS_TYPES = {
  ACTIVE: 0,
  REALIZED: 1,
  PUBLIC_HOLDINGS: 2,
};

export const PROVIDERS = {
  AUTH0: 'auth0',
};

export const ACCREDITATION_PAGES = ['IL', 'US', 'AU'];
export const COMMON = {
  ACCREDITED: 'accredited',
};

export const PROVIDER_TYPES = {
  UPA: 'UPA-Prod',
  UPA_STAGING: 'UPA-Staging',
  U_NAME_PASS: 'Username-Password-Authentication',
};

export const snapshotKpiMap = {
  monthly_net_burn: {
    label: 'Monthly Net Burn',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  users: { label: 'Users', isAccumulative: false, isMoney: false, unit: 'k', multiplier: 1000, orderWeight: 1 },
  customers: { label: 'Customers', isAccumulative: false, isMoney: false, unit: 'k', multiplier: 1000, orderWeight: 1 },
  cash_on_hand: {
    label: 'Cash on Hand',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  headcount: { label: 'Headcount', isAccumulative: false, isMoney: false, unit: '', multiplier: 1, orderWeight: 1 },
  revenues: { label: 'Revenue', isAccumulative: true, isMoney: true, unit: 'k$', multiplier: 1000, orderWeight: 1 },
  gmv: {
    label: 'Gross Merchandise Value',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  gross_margin: {
    label: 'Gross Margin',
    isAccumulative: false,
    isMoney: false,
    unit: '%',
    multiplier: 100,
  },
  number_of_kiosks: {
    label: 'Number of Kiosks',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  average_deposit: {
    label: 'Average Deposit',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  total_deposits: {
    label: 'Total Deposits',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  operating_profit_loss: {
    label: 'Operating Profit/Loss',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  vpn_active_users: {
    label: 'VPN Active Users',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  bookings: {
    label: 'Bookings',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  mrr: {
    label: 'Monthly Recurring Revenue',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  annualized_contract_value: {
    label: 'Annualized Contract Value',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  recognized_revenue: {
    label: 'Recognized Revenue',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  committed_mrr: {
    label: 'Committed Monthly Recurring Revenue',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  android_us_users: {
    label: 'Android US Users',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  ios_users: { label: 'iOS Users', isAccumulative: false, isMoney: false, unit: 'k', multiplier: 1000, orderWeight: 1 },
  registered_users: {
    label: 'Registered Users',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  active_applications: {
    label: 'Active Applications',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  daily_api_calls: {
    label: 'Daily API Calls',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  revenues_conversion: {
    label: 'Revenues (Conversion)',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  revenues_vip: {
    label: 'Revenues (VIP)',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  revenues_online: {
    label: 'Revenues (Online)',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  total_revenues: {
    label: 'Total Revenues',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  media_cost_conversion: {
    label: 'Media Cost (Conversion)',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  ebitda: { label: 'EBITDA', isAccumulative: true, isMoney: true, unit: 'k$', multiplier: 1000, orderWeight: 1 },
  avg_sale_price_vip: {
    label: 'AVG Sale Price (VIP)',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  artists_on_platform: {
    label: 'Artists on Platform',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  artworks_on_platform: {
    label: 'Artworks on Platform',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  mau: {
    label: 'Monthly Active Users',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  dau: {
    label: 'Daily Active Users',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  arpdau: {
    label: 'Average Revenue Per Daily Active User',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
    format: '($ 0,0[.]000)',
  },
  downloads: {
    label: 'Downloads',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  installs_per_day: {
    label: 'Installs Per Day',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  seven_day_retention: {
    label: '7 Day Retention',
    format: '0%',
    isAccumulative: false,
    isMoney: false,
    unit: '%',
    multiplier: 1,
    orderWeight: 1,
  },
  paid_licenses: {
    label: 'Paid Licenses',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  tests_run: {
    label: 'Tests Run',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  games_per_day: {
    label: 'Games Per Day',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  elements_experienced: {
    label: 'Elements Experienced',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  paying_customers: {
    label: 'Paying Customers',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  ltv: {
    label: 'LTV',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  deposits: {
    label: 'Deposits',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  buttons_sold: {
    label: 'Buttons Sold',
    isAccumulative: true,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  active_buttons: {
    label: 'Active Buttons',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  orders_per_button: {
    label: 'Orders per button',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  avg_loan_size: {
    label: 'Avg loan size',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  average_apr: {
    label: 'Average APR',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  number_of_issued_loans: {
    label: 'Number of issued loans',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  total_lent_capital: {
    label: 'Total lent capital',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  cost_per_funded_loan: {
    label: 'Cost per Funded Loan',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  issued_store_credit: {
    label: 'Issued Store Credit',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  average_deal_size: {
    label: 'Average Deal Size',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  number_of_funders: {
    label: 'Number of Funders',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  active_users: {
    label: 'Active Users',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  new_users_sign_ups: {
    label: 'New Users Sign Ups',
    isAccumulative: true,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  number_of_asks: {
    label: 'Number of Asks',
    isAccumulative: true,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  number_of_replies: {
    label: 'Number of Replies',
    isAccumulative: true,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  number_of_transactions: {
    label: 'Number of Transactions',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  deposit_volume: {
    label: 'Deposit Volume',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  churn: {
    label: 'Churn',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  bounce_rate: {
    label: 'Bounce Rate (%)',
    format: '0%',
    isAccumulative: false,
    isMoney: false,
    unit: '%',
    multiplier: 1,
    orderWeight: 1,
  },
  weekly_returning_users: {
    label: 'Weekly Returning Users (%)',
    format: '0%',
    isAccumulative: false,
    isMoney: false,
    unit: '%',
    multiplier: 1,
    orderWeight: 1,
  },
  weekly_pageviews: {
    label: 'Weekly Pageviews',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  number_of_issued_campaigns: {
    label: 'Number of issued Campaigns',
    isAccumulative: true,
    isMoney: false,
    unit: '',
    multiplier: 1,
    orderWeight: 1,
  },
  enterprise_mrr: {
    label: 'Enterprise Monthly Recurring Revenue',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  monthly_visits: {
    label: 'Monthly Visits',
    isAccumulative: true,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  monthly_returning_users: {
    label: 'Monthly Returning Users',
    format: '0%',
    isAccumulative: false,
    isMoney: false,
    unit: '%',
    multiplier: 1,
    orderWeight: 1,
  },
  user_uploaded_vehicles: {
    label: 'Vehicles Uploaded',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  user_uploaded_parts: {
    label: 'Parts Uploaded',
    isAccumulative: false,
    isMoney: false,
    unit: 'k',
    multiplier: 1000,
    orderWeight: 1,
  },
  second_day_retention: {
    label: '2nd Day Retention',
    format: '0%',
    isAccumulative: false,
    isMoney: false,
    unit: '%',
    multiplier: 1,
    orderWeight: 1,
  },
  ten_day_retention: {
    label: '10th Day Retention',
    format: '0%',
    isAccumulative: false,
    isMoney: false,
    unit: '%',
    multiplier: 1,
    orderWeight: 1,
  },
  thirty_day_retention: {
    label: '30th Day Retention',
    format: '0%',
    isAccumulative: false,
    isMoney: false,
    unit: '%',
    multiplier: 1,
    orderWeight: 1,
  },
  arr: {
    label: 'ARR',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  revenue_complete: {
    label: "Revenue 'Complete'",
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  immigration_revenue: {
    label: 'Immigration Revenue',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  us_revenue: {
    label: 'US Revenue',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  au_revenue: {
    label: 'AU Revenue',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  number_of_acres: {
    label: 'Number of Acres',
    isAccumulative: false,
    isMoney: false,
    unit: '',
    multiplier: 1,
    orderWeight: 1,
  },
  acv_booking: {
    label: 'Annual Contract Value (Bookings)',
    isAccumulative: true,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  trading_volume: {
    label: 'Trading Volume (Dollar)',
    isAccumulative: false,
    isMoney: true,
    unit: 'm$',
    multiplier: 1000000,
    orderWeight: 1,
  },
  transaction_volume: {
    label: 'Transaction Volume (Dollar)',
    isAccumulative: false,
    isMoney: true,
    unit: 'm$',
    multiplier: 1000000,
    orderWeight: 1,
  },
  number_of_buildings: {
    label: 'Number of Buildings',
    isAccumulative: false,
    isMoney: false,
    unit: '',
    multiplier: 1,
    orderWeight: 1,
  },
  videos_annotated: {
    label: 'Videos Annotated',
    isAccumulative: true,
    isMoney: false,
    unit: '',
    multiplier: 1,
    orderWeight: 1,
  },
  debt: {
    label: 'Debt',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  operating_expenses: {
    label: 'Operating Expenses',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  operating_cash_flow: {
    label: 'Operating Cash Flow',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  total_capital_raised_to_date: {
    label: 'Total Capital Raised To Date',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  grant: {
    label: 'Grant',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  net_profit_loss: {
    label: 'Net Profit/Loss',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  cash_position: {
    label: 'Cash Position',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  total_expenses: {
    label: 'Total Expenses',
    isAccumulative: false,
    isMoney: true,
    unit: 'k$',
    multiplier: 1000,
    orderWeight: 1,
  },
  capital_raised_on_the_platform: {
    label: 'Capital Raised on the Platform',
    isAccumulative: true,
    isMoney: true,
    unit: 'm$',
    multiplier: 1000000,
    orderWeight: 1,
  },
  pipeline_coverage_ratio: {
    label: 'Pipeline Coverage Ratio',
    isAccumulative: false,
    isMoney: false,
    unit: 'X',
    multiplier: 1,
    orderWeight: 1,
    format: '0[.]0',
  },
};

export const PAGE_TITLES = {
  dashboard: 'Dashboard',
  login: 'Log in',
  investmentOpportunities: 'Investment Opportunities',
  documents: 'My Documents',
  myKyc: 'My KYC',
  profile: 'My Profile',
  changePassword: 'Change Password',
  startups: 'Startups',
  invest: 'Invest',
  thankYou: 'Thank You',
  error: 'Error',
  accreditation: 'Accreditation',
  founder: 'Startup Application',
  uploader: 'Documents Uploader',
  sync: 'Start objects synchronisation'
};

export const realizedTypes = {
  realized: 'realized',
  secondary: 'Secondary',
  dividend: 'dividend',
  interest: 'interest',
  tax_expense: 'tax_expense',
  distributions: 'distributions',
};

export const entityDocuments = {
  upload: {
    individual: [
      {
        id: 'idDocument',
        apiKey: 'idDocument',
        mainTitle: 'ID/Passport',
        missingDesc: 'ID/Passport',
        options: { folder: 'kyc/id_coi' },
      },
      {
        id: 'w8w9Document',
        apiKey: 'w8w9Document',
        mainTitle: 'W8/W9 form',
        secondaryTitle: 'W8ben for non US citizens or W9 for US citizens',
        missingDesc: 'W-8BEN/9 (as applicable)',
        download: { field: 'taxResidency', united_states: 'W9_form', all: 'W8_for_individual' },
        options: { folder: 'kyc/w8_w9' },
        fieldDependency: 'taxResidency',
      },
      {
        id: 'accreditationDocument',
        apiKey: 'accreditationDocument',
        mainTitle: 'Accreditation verification form',
        secondaryTitle: 'To be signed by attorney, financial adviser or accountant',
        missingDesc: 'Accreditation Form – signed by an attorney/accountant/financial advisor',
        download: { field: 'residency', replace: true, all: 'XresidencyX_accreditation_verification_individual' },
        options: { folder: 'kyc/accreditation_verification' },
        fieldDependency: 'residency',
        hiddenFor: 'india or other',
      },
      {
        id: 'taxDeclarationDocument',
        apiKey: 'taxDeclarationDocument',
        mainTitle: 'Non-Israeli Tax Declaration form',
        missingDesc: 'Non-Israeli Tax Declaration',
        download: { all: 'Non_israeli_tax_declaration' },
        options: { folder: 'kyc/tax_declaration' },
        fieldDependency: 'taxResidency',
        hiddenFor: 'israel',
      },
      {
        id: 'bankAccountDocument',
        apiKey: 'bankAccountDocument',
        mainTitle: 'Bank Letter',
        missingDesc: 'Bank Letter',
        options: { folder: 'kyc/bank_account_letter' },
      },
    ],
    legal_entity: [
      {
        id: 'coiDocument',
        apiKey: 'coiDocument',
        mainTitle: 'Certificate of incorporation',
        missingDesc: 'Certificate of Incorporation',
        options: { folder: 'kyc/id_coi' },
        fieldDependency: 'whatTypeOfLegalEntity',
        displayFor: 'corporation',
      },
      {
        id: 'tdDocument',
        apiKey: 'coiDocument',
        mainTitle: 'Trust Deed',
        secondaryTitle: 'Trust Deed or Declaration/Registration',
        missingDesc: 'Trust Deed or Declaration/Registration',
        options: { folder: 'kyc/id_coi' },
        fieldDependency: 'whatTypeOfLegalEntity',
        displayFor: 'trust',
      },
      {
        id: 'crDocument',
        apiKey: 'coiDocument',
        mainTitle: 'Certificate of Registration',
        secondaryTitle: 'Certificate of Registration/Establishment',
        missingDesc: 'Certificate of Registration',
        options: { folder: 'kyc/id_coi' },
        fieldDependency: 'whatTypeOfLegalEntity',
        displayFor: 'partnership',
      },
      {
        id: 'raDocument',
        apiKey: 'mrDocument',
        mainTitle: 'Registration of all members/shareholders',
        secondaryTitle:
          'A document which specifies the shareholders and indicates the beneficial owner with over 10% of the corporation',
        missingDesc: 'Register of Members/Shareholders',
        options: { folder: 'kyc/id_coi' },
        fieldDependency: 'whatTypeOfLegalEntity',
        displayFor: 'corporation',
      },
      {
        id: 'paDocument',
        apiKey: 'mrDocument',
        mainTitle: 'Partnership Agreement',
        secondaryTitle: 'A single document which specifies the partnership members.',
        missingDesc: 'Partnership Agreement',
        options: { folder: 'kyc/id_coi' },
        fieldDependency: 'whatTypeOfLegalEntity',
        displayFor: 'partnership',
      },
      {
        id: 'idDocument',
        apiKey: 'idDocument',
        mainTitle: 'ID/Passport of beneficial owners',
        secondaryTitle:
          'A single document containing all ID/Passports of the beneficial owners over 10% of the X” (X being Corporation, Trust or Partnership accordingly)',
        missingDesc: 'ID/Passport of all beneficial owners with over 10% interest in the corporation',
         options: { folder: 'kyc/id_coi' },
      },
      {
        id: 'w8w9Document',
        apiKey: 'w8w9Document',
        mainTitle: 'W8/W9 form',
        secondaryTitle: 'W8ben for non US citizens or W9 for US citizens',
        missingDesc: 'W9/W8-BEN',
        download: { field: 'taxResidency', united_states: 'W9_form', all: 'W8_for_entity' },
        options: { folder: 'kyc/w8_w9' },
        fieldDependency: 'taxResidency',
      },
      {
        id: 'accreditationDocument',
        apiKey: 'accreditationDocument',
        mainTitle: 'Accreditation verification form',
        secondaryTitle: 'To be signed by attorney, financial adviser or accountant.',
        missingDesc:'Accreditation Form – signed by an attorney/accountant/financial advisor',
        download: { field: 'residency', replace: true, all: 'XresidencyX_accreditation_verification_entity' },
        options: { folder: 'kyc/accreditation_verification' },
        fieldDependency: 'residency',
        hiddenFor: 'india or other',
      },
      {
        id: 'taxDeclarationDocument',
        apiKey: 'taxDeclarationDocument',
        mainTitle: 'Non-Israeli Tax Declaration form',
        missingDesc: 'Non-Israeli Tax Declaration Form',
        download: { all: 'Non_israeli_tax_declaration' },
        options: { folder: 'kyc/tax_declaration' },
        fieldDependency: 'taxResidency',
        hiddenFor: 'israel',
      },
      {
        id: 'bankAccountDocument',
        apiKey: 'bankAccountDocument',
        mainTitle: 'Bank Letter',
        missingDesc: 'Bank Letter',
        options: { folder: 'kyc/bank_account_letter' },
      },
    ],
  },
};

// founder-page constants
export const businessModels = ['B2B', 'B2C', 'B2B2C', 'B2G', 'Other'];

export const businessStages = ['Pre-product', 'MVP', 'Beta', 'Pilots', 'Initial Revenue', 'Growth'];

export const numbersOfFounders = ['1', '2', '3', '4', '5'];

export const numbersOfFundings = ['0', '1', '2', '3', '4', '5'];

export const numbersOfEmployees = ['1-10', '11-20', '21-30', '31-40', 'Over 40'];

export const fundingStages = ['Pre-Seed', 'Seed', 'Seed+', 'A', 'B', 'C', 'Growth', 'Turnaround'];

export const securityTypes = ['Common Equity', 'Preferred Equity', 'Convertible Debt', 'SAFE', 'Advanced Equity'];
