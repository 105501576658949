import { createHttpClient } from '../services/http_request';
import moment from 'moment';
import { EMAIL_MAX_LENGTH } from './constants';
import Moment from 'moment';

export const getCommaSeparatedString = (num) => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '';
};

export const calculateUpdatedRollovers = (rollovers, newTotal) => {
  const suggestedTotal = rollovers.reduce((sum, item) => sum + (item.amountWithFees || 0), 0);
  const ratio = newTotal / suggestedTotal;

  let totalAssigned = 0;
  let amounts = rollovers.map((rollover) => {
    const roundedAmount = Math.floor((rollover.amountWithFees || 0) * ratio); 
    totalAssigned += roundedAmount;
    return {
      ...rollover,
      amountToInvest: roundedAmount,
    };
  });
  const remaining = newTotal - totalAssigned;
  if (remaining > 0)
  {
    amounts[amounts.length-1].amountToInvest+=remaining;
  }
  return amounts;
};

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const dateToShortFormat = (date) => {
  let newDate = new Date(date);
  let monthIndex = newDate?.getMonth();
  let monthName = monthNames[monthIndex];
  let year = newDate.getFullYear();

  return `${monthName}, ${year}`;
};

export const dateToTextFormat = (date) => {
  return moment(date).format('DD MMM YYYY');
};

export const dateToLongFormat = (date) => {
  return Moment(date).format('MMM Do, YYYY');
};

export const convertToInternationalCurrencySystem = (labelValue, toFixed = 0) => {
  const getNumber = (val, num, letter) => {
    return Math.abs(Number(val)) >= num ? Number((Math.abs(Number(labelValue)) / num).toFixed(toFixed)) + letter : '';
  };
  // Nine Zeroes for Billions
  return (
    getNumber(labelValue, 1.0e9, 'b') ||
    getNumber(labelValue, 1.0e6, 'm') ||
    getNumber(labelValue, 1.0e3, 'k') ||
    getNumber(labelValue, 1, '')
  );
};
export const truncateDecimal = (decimal, numOfNonZeroDigits) => {
  var minimumDecimal;
  if (decimal <= 0) {
    return 0;
  } else if (decimal > 1) {
    minimumDecimal = Math.pow(10, numOfNonZeroDigits);
    return Math.floor(decimal * minimumDecimal) / minimumDecimal;
  } else {
    var length = 1;
    minimumDecimal = Math.pow(10, numOfNonZeroDigits - 1);
    while (decimal < minimumDecimal) {
      length *= 10;
      decimal *= 10;
    }

    return parseInt(decimal) / length;
  }
};

export const getDateText = (investment) => {
  return investment.subRows.length > 1 ? 'Several Dates' : dateToShortFormat(investment.date);
};

export const uploadFile = (file, idToken, onSuccess, onFail, options = {}) => {
  const api = createHttpClient();
  let data = new FormData();
  data.append('file', file);
  data.append('options_kyc', JSON.stringify(options));
  api
    .post('/images/temp', data, {
      headers: {
        Authorization: 'Bearer ' + idToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
    .then(function (response) {
      onSuccess(response);
    })
    .catch(function (error) {
      onFail();
    });

  // The section down here is a failed try to make the upload work (it will work with this lower part active, but will probably break KYC page uploads)
  // The problem with the uploads through the founder page in general is that a user can be anonymous and use that page, compared to the rest of the site where a user has to be logged in to interact
  // That's why there's a need to surpass certain mechanisms where a logged in user is needed for the process in founder page's upload system

  // const api = createHttpClient();
  // const headers = {'Content-Type': 'application/json;charset=UTF-8'};
  // let data = new FormData();
  // data.append('file', file);

  // if (options && Object.keys(options).length > 0) {
  //   data.append('options', JSON.stringify(options));
  // }

  // if (idToken) {
  //   headers.Authorization = 'Bearer ' + idToken;
  // }

  // api
  //   .post('/images/temp', data, {headers})
  //   .then(function (response) {
  //     onSuccess(response);
  //   })
  //   .catch(function (error) {
  //     onFail();
  //   });
};

export const humanRoundType = (roundType) => {
  var hash = {
    common_equity: 'Common Equity',
    preferred_equity: 'Preferred Equity',
    convertible_debt: 'Convertible Debt',
    safe: 'SAFE',
    token: 'Token',
    fund: 'Fund',
  };
  return hash[roundType];
};

export const humanize = (str) => {
  return str ? str.replaceAll('_', ' ') : '';
};

export const capitalize = (str) => {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const titleSlug = (str) => {
  return capitalize(str?.replaceAll('-', ' '));
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank');
  if (newWindow) newWindow.opener = null;
};
export const getSectorImage = (sector) => {
  try {
    let img = require(`../assets/roundbox/${sector?.toLowerCase().replace(/ /g, '-')}.jpg`);
    return img;
  } catch (ex) {
    return require('../assets/roundbox/default.jpg');
  }
};
export const getOwner = (country, state) => {
  const getUnitedStatesDefaultOwner = () => {
    return 'alex@iangels.co';
  };

  const getDefaultOwner = () => {
    return 'alex@iangels.co';
  };

  return country.region === 'United_States'
    ? OwnerByState[state] || getUnitedStatesDefaultOwner()
    : OwnerByRegion[country.region] || getDefaultOwner();
};

export const isEmailValid = (email, setEmailError) => {
  if (email.length === 0) {
    setEmailError('Email is required.');
    return false;
  }
  if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
    setEmailError('Email is not valid.');
    return false;
  }

  if (email.trim().length > EMAIL_MAX_LENGTH) {
    setEmailError('Email is too long');
    return false;
  }

  setEmailError('');
  return true;
};

export const isPhoneValid = (phone, setPhoneError) => {
  if (phone.length === 0) {
    setPhoneError('Phone is required.');
    return false;
  }
  if (!/^[0-9-+]+$/.test(phone)) {
    setPhoneError('Phone is not valid.');
    return false;
  }
  setPhoneError('');
  return true;
};

const OwnerByState = {
  AL: 'alex@iangels.co',
  CA: 'alex@iangels.co',
  CO: 'alex@iangels.co',
  DC: 'alex@iangels.co',
  FL: 'alex@iangels.co',
  HI: 'alex@iangels.co',
  NV: 'alex@iangels.co',
  NJ: 'alex@iangels.co',
  MD: 'alex@iangels.co',
  TX: 'alex@iangels.co',
  WA: 'alex@iangels.co',
  AK: 'alex@iangels.co',
  KS: 'alex@iangels.co',
  LA: 'alex@iangels.co',
  OR: 'alex@iangels.co',
  TN: 'alex@iangels.co',
  AR: 'alex@iangels.co',
  DE: 'alex@iangels.co',
  ID: 'alex@iangels.co',
  IN: 'alex@iangels.co',
  IA: 'alex@iangels.co',
  KY: 'alex@iangels.co',
  ME: 'alex@iangels.co',
  MT: 'alex@iangels.co',
  NE: 'alex@iangels.co',
  NH: 'alex@iangels.co',
  NM: 'alex@iangels.co',
  NY: 'alex@iangels.co',
  NC: 'alex@iangels.co',
  ND: 'alex@iangels.co',
  OH: 'alex@iangels.co',
  OK: 'alex@iangels.co',
  MA: 'alex@iangels.co',
  MI: 'alex@iangels.co',
  MN: 'alex@iangels.co',
  MS: 'alex@iangels.co',
  MO: 'alex@iangels.co',
  RI: 'alex@iangels.co',
  SC: 'alex@iangels.co',
  SD: 'alex@iangels.co',
  UT: 'alex@iangels.co',
  VT: 'alex@iangels.co',
  WV: 'alex@iangels.co',
  WI: 'alex@iangels.co',
  WY: 'alex@iangels.co',
  AZ: 'alex@iangels.co',
  CT: 'alex@iangels.co',
  IL: 'alex@iangels.co',
  PA: 'alex@iangels.co',
  VA: 'alex@iangels.co',
  GA: 'alex@iangels.co',
};

const OwnerByRegion = {
  United_States: 'alex@iangels.co',
  Canada: 'alex@iangels.co',
  Asia: 'alex@iangels.co',
  Asia_Pac: 'alex@iangels.co',
  Australia: 'alex@iangels.co',
  South_Africa: 'alex@iangels.co',
  Nordics: 'alex@iangels.co',
  Israel: 'alex@iangels.co',
  United_Kingdom: 'alex@iangels.co',
  Eastern_Europe: 'alex@iangels.co',
  Europe: 'alex@iangels.co',
  Middle_East: 'alex@iangels.co',
  Turkey: 'alex@iangels.co',
  Africa: 'alex@iangels.co',
  South_America: 'alex@iangels.co',
  Mexico: 'alex@iangels.co',
};

export const flattenObject = (obj, prefix = '') => {
  const flattened = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const nestedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        const nestedFlattened = flattenObject(obj[key], nestedKey);
        Object.assign(flattened, nestedFlattened);
      } else {
        flattened[nestedKey] = obj[key];
      }
    }
  }

  return flattened;
};
