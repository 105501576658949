import React, { useState } from 'react';

// mui utils
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// iangels utils
import { convertToInternationalCurrencySystem } from '../../helpers/iangels';
import { getCloudinaryLogo } from '../../helpers/cloudinary';
import { track } from '../../services/analytics';
import { useNavigate } from 'react-router-dom';
import Text from '../../components/text';
import MemoTitle from '../../components/Memo/MemoTitle';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';
import {setPopup} from '../RolloverPopup/actions';

// third party utils
import { useDispatch } from 'react-redux';

const CompanyPageHeader = ({
  logo,
  tagLine,
  sector,
  name,
  investOptions,
  hideMinimum,
  currentRoundId,
  isFund,
  canInvest,
  isUserRestricted,
  isUserVerified,
  isUserAccredited,
  isRollover,
  userCannotInvest
}) => {
  const isMobile = IsMobileDevice();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [otherAmount, setOtherAmount] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [amountErrorText, setAmountErrorText] = React.useState(null);
  const [showCannotInvestAlert, setShowCannotInvestAlert] = useState(false);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOtherAmountChange = (event) => {
    setOtherAmount(event.target.value);
  };

  const isAmountValid = () => {
    if (otherAmount === '') {
      setAmountErrorText('Investment amount required.');
      return false;
    }

    if (Number(otherAmount) < Number(investOptions.usd.minimumInvestment)) {
      setAmountErrorText(`Minimum investment is US$ ${investOptions.usd.minimumInvestment}`);
      return false;
    }

    setAmountErrorText('');
    return true;
  };

  const hasInvestmentOptions = () => {
    return investOptions?.usd?.amounts?.length > 0;
  };

  const handleInvest = () => {
    const isValid = isAmountValid();

    if (!isValid || !currentRoundId) {
      return;
    }
    track('Investment Widget Custom Amount Click', { category: 'Investment', startup: name });
    investNavigate(otherAmount);
  };

  const openRollover = () => {
    dispatch(setPopup(true));
  };


  const investNavigate = (amount) => {
    if(userCannotInvest){
      setShowCannotInvestAlert(true);
      return;
    }
    navigate(`/invest/${currentRoundId}/entity/${amount}`);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'conditions-popover' : undefined;

  return (
    <Stack
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
      className={isMobile ? 'mobileSection' : null}
    >
      <Stack display="flex" alignItems="flex-start" flexDirection="column" spacing={3}>
        <Stack flexDirection="row">
          <Stack>
            <img
              src={getCloudinaryLogo(logo, 120)}
              alt="company logo"
              className="startup-company-img"
              style={isMobile ? { maxWidth: 80 } : {}}
              onClick={() => track('Click Startup Logo')}
            />
          </Stack>
          <Stack direction="column" alignItems="flex-start" justifyContent="space-between">
            <Stack>
              <Text text={name} classes={`sp-title ${isMobile ? ' f-size-18' : ' f-size-24'}`} />
            </Stack>
            <Stack>
              <Text text={tagLine} classes="f-f-g f-w-400 f-size-14 lh-16 g4-c" />
            </Stack>
            <Stack className="f-f-tgf f-w-500 f-size-12 lh-18 g-c p-5 ribbon-wrapper ribbon-sector capitalize">
              {sector}
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          {isMobile ? null : (
            <Typography className="f-gl-400 f-size-14 max-w-800">
              <span className="bold">Confidentiality Reminder:</span> The information below is highly confidential and
              includes proprietary and sensitive business information of the company and/or iAngels. Accordingly, you
              are required to keep such information in strict confidence and not to copy or disclose it in any manner,
              nor to otherwise use it for any purpose other than for your personal investment in the company through the
              iAngels platform.
            </Typography>
          )}
        </Stack>
      </Stack>
      {isMobile ? <Divider orientation="horizontal" style={{ width: 'auto', margin: '5px 0px 20px 0px' }} /> : null}
      {/* invest section */}
      {hasInvestmentOptions() && canInvest && !isUserRestricted && isUserVerified && isUserAccredited ? (
        <>
          <Divider orientation="vertical" style={{ height: 'auto', margin: '0 15px' }} />
          <Stack display="flex" spacing={2}>
            <MemoTitle title="Invest" />
            {isRollover? ( <Button
                  variant="contained"
                  className="g4-bg fullwidth mb-10 w-c f-w-400 f-size-16 f-f-tgf  capitalize pointer"
                  style={{ width: 280, height: '50px' }}
                  onClick={openRollover}
                >
                 <ArrowBackIosIcon/>
                  Back to reinvest form
                </Button>):
              <>
              <Stack direction="row" spacing={1}>
              {investOptions.usd.amounts.map((amount, index) => (
                <Button
                  fullWidth
                  variant="contained"
                  value={index}
                  className="btn-invest f-size-12 p-15 nowrap"
                  style={{ letterSpacing: '0.06em', height: '42px' }}
                  key={amount}
                  onClick={() => {
                    track('Investment Widget Predefined Button Click', {
                      category: 'Investment',
                      amount: amount,
                      startup: name,
                    });
                    investNavigate(amount);
                  }}
                >
                  {'US$ ' + convertToInternationalCurrencySystem(amount).toUpperCase()}
                </Button>
              ))}
            </Stack>
            <Stack direction="row" spacing={2} display="flex">
              <Stack direction="row" spacing={3} style={{ width: '100%' }}>
                <TextField
                  id="other-amount"
                  label={hideMinimum ? '   ' :
                    `Other (US$ ${convertToInternationalCurrencySystem(
                    investOptions?.usd.minimumInvestment
                  )} minimum)*`}
                  variant="standard"
                  className="f-size-8 text-field"
                  value={otherAmount}
                  name="otherAmount"
                  onChange={handleOtherAmountChange}
                  helperText={amountErrorText}
                  error={!!amountErrorText}
                  type="number"
                  InputProps={{
                    inputProps: { min: investOptions?.usd?.minimumInvestment },
                  }}
                  InputLabelProps={{ style: { fontSize: '12px', transformOrigin: 'bottom left', paddingTop: '10px' } }}
                  sx={{ width: '75%' }}
                />

                <Button
                  variant="contained"
                  className="btn-invest p-10 mt-20 f-size-14"
                  style={{ width: 130, height: '30px' }}
                  onClick={handleInvest}
                >
                  Invest
                </Button>
              {showCannotInvestAlert && (
                <div className="overlay" onClick={() => setShowCannotInvestAlert(false)}>
                  <div className="alert-box" onClick={(e) => e.stopPropagation()}>
                    <button className="close-btn" onClick={() => setShowCannotInvestAlert(false)}>✖</button>
                    <p>Thank you for your interest in {name}.<br/> Our team will be in touch with you shortly to complete the investment process.</p>
                  </div>
                </div>
              )}
              </Stack>
             
            </Stack>
            {!('12 Angels' === name) && !isFund ? (
              <Text
                classes="f-f-gl f-w-700 f-size-12 mt-10 lh-16 ws-5"
                text="Management and administrative fees will be added"
              />
            ) : null}
             </>
              }
          </Stack>
          {isMobile ? (
            <>
              <span
                className="f-gl-400 f-size-14 g-c mt-20 pointer underline"
                aria-describedby={popoverId}
                onClick={handlePopoverClick}
                style={{ maxWidth: 150 }}
              >
                {'Terms & Conditions'}
              </span>
              <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Text
                  classes="f-gl-400 f-size-12"
                  style={{ margin: 15 }}
                  text="Confidentiality Reminder: The information below is highly confidential and includes proprietary and sensitive business information of the company and/or iAngels. Accordingly, you are required to keep such information in strict confidence and not to copy or disclose it in any manner, nor to otherwise use it for any purpose other than for your personal investment in the company through the iAngels platform."
                />
              </Popover>
            </>
          ) : null}
        </>
      ) : null}
    </Stack>
  );
};

export default CompanyPageHeader;
