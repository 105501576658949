import React, { useState } from 'react';

// mui utils
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';

// iangels utils
import KycSubmitDocuments from './KycSubmitDocuments';
import Text from '../text';

const KycForm = ({ kyc, isInvestmentFlow }) => {
  const isLegalEntity = (value) => {
    return value === 'legal_entity';
  };

  const [address, setAddress] = useState(kyc?.address || '');
  const [selectedResidency, setSelectedResidency] = useState(kyc?.residency || '');
  const [selectedTaxResidency, setSelectedTaxResidency] = useState(kyc?.taxResidency || '');
  const [taxResidencyOther, setTaxResidencyOther] = useState(kyc?.taxResidencyOther || '');
  const [selectedHowYouInvest, setSelectedHowYouInvest] = useState(kyc?.type || 'individual');
  const [selectedWhatTypeOfLegalEntity, setSelectedWhatTypeOfLegalEntity] = useState(
    kyc?.type === 'legal_entity' ? kyc?.legalType || 'corporation' : ''
  );
  const [showLegalEntitiesSection, setShowLegalEntitiesSection] = useState(isLegalEntity(kyc?.type));
  const [errors, setErrors] = useState({
    residency: '',
    taxResidency: '',
    taxResidencyOther: '',
    requiredFieldsError: '',
  });

  const getSectionTitle = (text) => {
    return <Text classes="f-f-tgf f-w-400 f-size-16 g4-c" text={text} />;
  };

  const handleHowYouInvestChange = (event) => {
    setSelectedHowYouInvest(event.target.value);
    setShowLegalEntitiesSection(isLegalEntity(event.target.value));
  };

  const handleLegalEntityTypeChange = (event) => {
    setSelectedWhatTypeOfLegalEntity(event.target.value);
  };

  const residencies = [
    { label: 'Israel', value: 'israel' },
    { label: 'United States', value: 'united_states' },
    { label: 'Australia', value: 'australia' },
    { label: 'Brazil', value: 'brazil' },
    { label: 'British Virgin Islands', value: 'british_virgin_islands' },
    { label: 'Canada', value: 'canada' },
    { label: 'Cyprus', value: 'cyprus' },
    { label: 'EU (Including Switzerland)', value: 'eu' },
    { label: 'Hong Kong', value: 'hong_kong' },
    { label: 'India', value: 'india' },
    { label: 'Nigeria', value: 'nigeria' },
    { label: 'Saudi Arabia', value: 'saudi_arabia' },
    { label: 'Singapore', value: 'singapore' },
    { label: 'Turkey', value: 'turkey' },
    { label: 'UK', value: 'uk' },
    { label: 'Other', value: 'other' },
  ];

  const taxResidencies = [
    { label: 'Israel', value: 'israel' },
    { label: 'United States', value: 'united_states' },
    { label: 'Other', value: 'other' },
  ];

  return (
    <Stack spacing={2} direction="column" alignItems="start" style={{ width: '100%' }}>
      <div style={{ marginTop: 40 }}>
        {getSectionTitle('How are you investing?')}
        <RadioGroup
          aria-labelledby="how-are-you-investing-buttons-group"
          defaultValue={selectedHowYouInvest}
          name="how-are-you-investing-buttons-group"
          onChange={handleHowYouInvestChange}
        >
          <FormControlLabel
            value="individual"
            control={<Radio />}
            label="As an individual"
            disabled={kyc?.type != null}
            className={selectedHowYouInvest === 'individual' ? 'g3-c' : ''}
          />
          <FormControlLabel
            value="legal_entity"
            control={<Radio />}
            label="As a legal entity"
            disabled={kyc?.type != null}
            className={selectedHowYouInvest === 'legal_entity' ? 'g3-c' : ''}
          />
        </RadioGroup>
        {errors.howYouInvest ? <Text classes="r-c mt-5" text={errors.howYouInvest} /> : null}
      </div>
      {showLegalEntitiesSection ? (
        <div style={{ marginTop: 40 }}>
          {getSectionTitle('What type of legal entity?*')}
          <RadioGroup
            aria-labelledby="type-of-legal-entity-buttons-group"
            defaultValue={selectedWhatTypeOfLegalEntity}
            name="type-of-legal-entity-buttons-group"
            onChange={handleLegalEntityTypeChange}
          >
            <FormControlLabel
              value="corporation"
              control={<Radio />}
              label="Corporation"
              disabled={kyc?.legalType != null}
              className={selectedWhatTypeOfLegalEntity === 'corporation' ? 'g3-c' : ''}
            />
            <FormControlLabel value="trust" control={<Radio />} label="Trust" disabled={kyc?.legalType != null} />
            <FormControlLabel
              value="partnership"
              control={<Radio />}
              label="Partnership"
              disabled={kyc?.legalType != null}
              className={selectedWhatTypeOfLegalEntity === 'partnership' ? 'g3-c' : ''}
            />
          </RadioGroup>
          {errors.whatTypeOfLegalEntity ? (
            <Text
              classes="r-c mt-5"
              text={errors.whatTypeOfLegalEntity}
              disabled={kyc?.whatTypeOfLegalEntity != null}
            />
          ) : null}
        </div>
      ) : null}

      <div style={{ marginTop: 40 }}>
        {getSectionTitle('What is your residency?')}
        <FormControl variant="outlined" className="text-field min-w-300 mt-15">
          <InputLabel id="residency-select-standard-label" style={{ background: '#fff', padding: '0 5px' }}>
            Residency*
          </InputLabel>
          <Select
            labelId="residency-select-standard-label"
            id="residency-select"
            value={selectedResidency}
            onChange={(event) => {
              setSelectedResidency(event.target.value);
              setErrors({
                ...errors,
                residency: '',
              });
            }}
            name="residency"
            disabled={kyc?.residency != null}
          >
            {residencies.map((residency) => (
              <MenuItem key={residency.label} value={residency.value}>
                {residency.label}
              </MenuItem>
            ))}
          </Select>
          {errors.residency ? <Text classes="r-c mt-5" text={errors.residency} /> : null}
        </FormControl>
      </div>

      <div style={{ marginTop: 40 }}>
        {getSectionTitle('What is your tax residency?')}

        <FormControl variant="outlined" className="text-field min-w-300 mt-15">
          <InputLabel id="tax-residency-select-standard-label" style={{ background: '#fff', padding: '0 5px' }}>
            Tax Residency*
          </InputLabel>
          <Select
            labelId="tax-residency-select-standard-label"
            id="tax-residency-select"
            value={selectedTaxResidency}
            onChange={(event) => {
              setSelectedTaxResidency(event.target.value);
              setErrors({
                ...errors,
                taxResidency: '',
              });
            }}
            name="tax-residency"
            disabled={kyc?.taxResidency != null}
          >
            {taxResidencies.map((taxResidency) => (
              <MenuItem key={taxResidency.label} value={taxResidency.value}>
                {taxResidency.label}
              </MenuItem>
            ))}
          </Select>          
          {errors.taxResidency ? <Text classes="r-c mt-5" text={errors.taxResidency} /> : null}
        </FormControl>
      </div>
      {selectedResidency === 'other' && 
      <div style={{ marginTop: 40 }}>
        {getSectionTitle('Country of your residency')}
        <TextField
          className="text-field min-w-300"
          variant="outlined"
          disabled={(kyc?.taxResidencyOther !== null && 
            kyc?.taxResidencyOther !== undefined && kyc?.taxResidencyOther !== '')}
          margin="normal"
          id="tax-residency-other"
          label="Country of your residency*"
          name="tax-residency-other"
          value={taxResidencyOther}
          autoComplete="taxResidencyOther"
          onChange={(e) => {
            setTaxResidencyOther(e.target.value);
            setErrors({
              ...errors,
              taxResidencyOther: '',
            });
          }}
          autoFocus           
        />
        {errors.taxResidencyOther ? <Text classes="r-c mt-5" text={errors.taxResidencyOther} /> : null}
      </div>}
      <div style={{ marginTop: 40 }}>
        {getSectionTitle('What is your billing address?')}
        <TextField
          className="text-field min-w-300"
          variant="outlined"
          disabled={kyc?.address !== null && kyc?.address !== undefined && kyc?.address !== ''}
          margin="normal"
          id="address"
          label="Address"
          name="address"
          value={address}
          autoComplete="address"
          onChange={(e) => setAddress(e.target.value)}
          autoFocus
        />
      </div>
      {selectedResidency === 'other' && <Text classes="f-size-16 italic g4-c f-weight-500 f-f-pmp" text="Our compliance team will reach out to you shortly to guide you through the accreditation process for your country." />}

      <div className="f-f-pmp f-w-500 f-size-16 g4-c italic justify mt-40">
        For your KYC we require the below documents. If you don’t have all of them at hand right now, you can upload the
        ones you have and send us the rest later to: <span className="g3-c">backoffice@iangels.com</span>
      </div>
      
      <div style={{ width: '100%' }}>
        <KycSubmitDocuments
          kyc={{
            name: kyc?.name,
            howYouInvest: selectedHowYouInvest,
            whatTypeOfLegalEntity: selectedWhatTypeOfLegalEntity,
            address: address,
            residency: selectedResidency,
            taxResidency: selectedTaxResidency,
            taxResidencyOther: taxResidencyOther,
            documents: kyc?.documents,
          }}
          errors={errors}
          setErrors={setErrors}
          isInvestmentFlow={isInvestmentFlow}
        />
      </div>
    </Stack>
  );
};

export default KycForm;
