import React from "react";

import TextField from '@mui/material/TextField';

const TextFieldForm = ({...props})=>{
    return (
        <TextField 
            size="small"
            className="text-field min-w-300 m-10"
            autoFocus
            {...props}
        />
    );
};
export default TextFieldForm;