import React, { useCallback, useEffect, useState, useMemo } from 'react';
import NumberFormat from 'react-number-format';

// mui utils
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// iAngels utils
import { convertToInternationalCurrencySystem, dateToTextFormat, humanize } from '../helpers/iangels';
import { snapshotKpiMap } from '../helpers/constants';
import Text from './text';

// third party utils
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
//import 'chartjs-plugin-datalabels';
//import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import _ from 'lodash';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const FinancialsAndTraction = ({ kpis, monthlySnapshotIsAvailable, defaultPeriod, isMobile }) => {
  // state
  const [kpiPeriod, setKpiPeriod] = useState(defaultPeriod || 'quarterly');
  const [sortedKpis, setSortedKpis] = useState({});
  const [kpiPeriods, setKpiPeriods] = useState([]);
  const [kpiGroups, setKpiGroups] = useState({});
  const [kpiGroupsValue, setKpiGroupsValue] = useState({});
  const [dataSet, setDataSet] = useState([]);
  const [kpiType, setKpiType] = useState('');
  const [labels, setLabels] = useState([]);

  /* kpi order types: Change the order of the kpis list according to the arr kpiTypeOrder from top to bottom */
  const kpiTypeOrder = useMemo(() => {
    return [
      'trading_volume',
      'transaction_volume',
      'gmv',
      'capital_raised_on_the_platform',
      'bookings',
      'arr',
      'mrr',
      'committed_mrr',
      'pipeline_coverage_ratio',
      'revenues',
      'gross_margin',
      'recognized_revenue',
      'total_revenue',
      'operating_expenses',
      'total_expenses',
      'operating_profit_loss',
      'ebitda',
      'net_profit_loss',
      'cash_on_hand',
      'cash_position',
      'total_capital_raised_to_date',
      'monthly_net_burn',
      'operating_cash_flow',
      'debt',
      'grant',
    ];
  }, []);

  // grouping functions
  const monthGroupingFunc = (kpi) => {
    return moment(kpi.date).format('MM/Y');
  };

  const yearGroupingFunc = (kpi) => {
    const momentDate = moment(kpi.date);
    return momentDate.year();
  };

  const quarterGroupingFunc = (kpi) => {
    var momentDate = moment(kpi.date);
    return 'Q' + momentDate.quarter() + ' ' + momentDate.year();
  };

  const groupingFuncsByPeriod = useMemo(() => {
    return {
      monthly: monthGroupingFunc,
      yearly: yearGroupingFunc,
      quarterly: quarterGroupingFunc,
    };
  }, []);

  const groupingValuesByPeriod = useMemo(() => {
    return {
      monthly: _.groupBy(sortedKpis, monthGroupingFunc),
      yearly: _.groupBy(sortedKpis, yearGroupingFunc),
      quarterly: _.groupBy(sortedKpis, quarterGroupingFunc),
    };
  }, [sortedKpis]);

  const isAccumulativePeriod = () => {
    return _.includes(['quarterly', 'yearly'], kpiPeriod);
  };

  const isKpiMoney = (valueType) => {
    return valueType ? (snapshotKpiMap[valueType] || {}).isMoney || false : false;
  };

  const isKpiNegative = (value) => {
    return value < 0;
  };

  const existValue = (value) => {
    return value !== '--' && value !== 0 && value !== undefined && value !== '';
  };

  const getKpiPeriod = useCallback(() => {
    let periodNames = [];
    const periods = groupingValuesByPeriod[kpiPeriod];

    if (periods) {
      Object.keys(periods).map((key, index) => periodNames.push(key));
    }
    return periodNames;
  }, [kpiPeriod, groupingValuesByPeriod]);

  const buildKpiGroupsValue = useCallback(() => {
    const periodFunc = groupingFuncsByPeriod[kpiPeriod];
    const groupedByPeriod = _.groupBy(sortedKpis, periodFunc);
    const result = {};
  
    Object.entries(groupedByPeriod).forEach(([period, items]) => {
      const groupedByType = _.groupBy(items, 'type');
      result[period] = {};
  
      Object.entries(groupedByType).forEach(([type, values]) => {
        const useLatest = !(snapshotKpiMap[type] || {}).isAccumulative;
  
        if (useLatest) {
          const latest = _.maxBy(values, (item) => moment(item.date));
          result[period][type] = latest?.value ?? '--';
        } else {
          result[period][type] = _.sumBy(values, 'value');
        }
      });
    });
  
    return result;
  }, [sortedKpis, groupingFuncsByPeriod, kpiPeriod]);
  
  const getLastUpdateDateByType = () => {
    var latestKpi = _.reduce(
      kpiGroups[kpiType] || [],
      function (memo, kpi) {
        return memo.date > kpi.date ? memo : kpi;
      },
      { date: '' }
    );

    return latestKpi.date;
  };
  const data = {
    labels,
    datasets: [
      {
        label: snapshotKpiMap[kpiType]?.label || humanize(kpiType),
        data: dataSet,
        backgroundColor: '#4cb9ba',
      },
    ],
  };

  //format value in the table
  const formatKpiValue = (key, value) => {
    switch (value) {
      case '--':
        return value;
      case 0:
        return (isKpiMoney(key) ? '$' : '') + value;
      default:
        let ret = isKpiMoney(key)
          ? '$' + convertToInternationalCurrencySystem(Math.ceil(value), 1)
          : value.toLocaleString();

        if (isKpiNegative(value)) {
          ret = '(' + ret + ')';
        }

        return ret;
    }
  };

  // on type changed
  const handleChange = useCallback((event) => {
    setKpiType(event.target.value);
  }, []);
  //on period changed
  const handleChangePeriod = useCallback((event) => {
    setKpiPeriod(event.target.value);
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }));

  useEffect(() => {
    setKpiPeriods(groupingValuesByPeriod[kpiPeriod] ? getKpiPeriod(groupingValuesByPeriod[kpiPeriod]) : null);
  }, [kpiPeriod, getKpiPeriod, groupingValuesByPeriod]);

  useEffect(() => {
    const valuesByPeriod = Object.entries(kpiGroupsValue).reduce(
      (acc, [period, types]) => {
        if (existValue(types[kpiType])) {
          acc.labels.push(period);
          acc.dataSet.push(types[kpiType]);
        }
        return acc;
      },
      { labels: [], dataSet: [] }
    );
  
    setLabels(valuesByPeriod.labels);
    setDataSet(valuesByPeriod.dataSet);
  }, [kpiGroupsValue, kpiType]);

  useEffect(() => {
    const groupedData = buildKpiGroupsValue();
    setKpiGroupsValue(groupedData);
  }, [buildKpiGroupsValue]);
  
  useEffect(() => {
    //kpis
    //1 - sorting by date desc
    const kpisSortedByDate = _.orderBy(kpis, 'date', 'desc');
    setSortedKpis(kpisSortedByDate);
    //2 - group by type
    const kpisGroupByType = _.groupBy(kpisSortedByDate, 'type');
    //3 - sorting type by fixed array :
    let kpisOrdered = [];
    //add the data by defined order
    kpiTypeOrder
      .filter((v) => Object.keys(kpisGroupByType).includes(v))
      .map((e) => (kpisOrdered[e] = kpisGroupByType[e]));

    //add the data that not in the defined ordered
    Object.keys(kpisGroupByType)
      .filter((v) => !kpiTypeOrder.includes(v))
      .map((e) => (kpisOrdered[e] = kpisGroupByType[e]));
    setKpiType(Object.keys(kpisOrdered)[0]);
    setKpiGroups(kpisOrdered);
  }, [kpis, kpiTypeOrder, isMobile]);
  //end useEffect.
  const options = {
    layout: {
      padding: {
        top: 20,
      },
    },
    responsive: true,

    plugins: {
      datalabels: {
        formatter: function (value, context) {
          return formatKpiValue(kpiType, value);
        },
        display: true,
        color: 'rgba(0,0,0,0.87)',
        anchor: function (value, context) {
          return value?.dataset?.data[value?.dataIndex] >= 0 ? 'end' : 'start';
        },
        offset: function (value, context) {
          return value?.dataset?.data[value?.dataIndex] >= 0 ? -20 : -5;
        },
        align: 'start',
        font: function (context) {
          const w = context.chart.width;
          return {
            size: w < 512 ? 8 : 12,
            weight: 350,
          };
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'iAngels Bar Chart',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },

      y: {
        grid: {
          display: false,
        },
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return formatKpiValue(kpiType, value);
          },
        },
      },
    },
  };

  return (
    <>
      <Text
        text={`Table of ${snapshotKpiMap[kpiPeriod]?.label || humanize(kpiPeriod)} ${
          snapshotKpiMap[kpiType]?.label || humanize(kpiType)
        }`}
        classes="f-f-tgf f-w-500 g4-c f-size-14"
      />

      <Grid container className="g4-c f-size-16 f-f-tgf f-w-500" sx={{ my: 2, opacity: '0.8' }}>
        <Grid item sx={{ my: 1, mr: 1 }} xs={isMobile ? 5 : 'auto'}>
          Selected KPI
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <Select id="selected-kpi" value={kpiType} onChange={handleChange} variant="standard" sx={{ fontSize: 16 }}>
            {Object.keys(kpiGroups).map((key, index) => (
              <MenuItem key={index} value={key}>
                {snapshotKpiMap[key]?.label || humanize(key)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item sx={{ my: 1, mr: 1 }} xs={isMobile ? 5 : 'auto'}>
          Period
        </Grid>
        <Grid item xs={isMobile ? 6 : 2}>
          <Select id="period" value={kpiPeriod} onChange={handleChangePeriod} variant="standard" sx={{ fontSize: 16 }}>
            {monthlySnapshotIsAvailable ? <MenuItem value="monthly">Monthly</MenuItem> : null}
            <MenuItem value="quarterly">Quarterly</MenuItem>
            <MenuItem value="yearly">Yearly</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4} display="flex" justifyContent="flex-end">
          {isAccumulativePeriod() ? (
            <Text
              text={'Updated as of ' + dateToTextFormat(getLastUpdateDateByType())}
              classes={isMobile ? 'f-size-10 f-f-g' : 'f-f-pmp f-w-500 f-size-16 g4-c italic mt-5'}
            />
          ) : null}
        </Grid>
      </Grid>

      <Bar options={options} data={data} />

      <div className="fullwidth mt-20 mb-20">
        <TableContainer component={Paper}>
          <Table
            stickyHeader
            aria-label="simple table"
            style={{ minWidth: 800, overflow: 'scroll', tableLayout: 'fixed' }}
          >
            <TableHead>
              <TableRow>
                <TableCell className="sticky" />
                {kpiPeriods
                  ? kpiPeriods.map((columnsName, index) => (
                      <TableCell className="f-f-gl f-w-600 f-size-16 sticky" key={index}>
                        {columnsName}
                      </TableCell>
                    ))
                  : null}
              </TableRow>
            </TableHead>
            <TableBody>
            {kpiPeriods.length > 0
             && Object.keys(kpiGroupsValue).length > 0
             && Object.entries(kpiGroups)
              .filter(([key]) => {
                const lastTwoPeriods = [...kpiPeriods].slice(0,2);
                // Keep the row only if the value exists (is not undefined) in both
                return lastTwoPeriods.some((period) => kpiGroupsValue[period]?.[key] !== undefined);
              }).map(([key, value]) => (
                <TableRow key={key} hover>
                  <TableCell className="table-cell-sticky" align="left">
                    <span className="f-f-gl f-w-600 f-size-16">{snapshotKpiMap[key]?.label || humanize(key)}</span>
                    <span className="f-size-16">{`as of ${dateToTextFormat(value?.sort((a, b) => new Date(b.date) - new Date(a.date))?.[0]?.date)}`}</span>
                  </TableCell>
                  {kpiPeriods.map((period, index) => {
                    const value = kpiGroupsValue[period]?.[key] ?? '--';
                    return (
                      <StyledTableCell key={index} className="f-size-16 f-gl-400">
                        <NumberFormat
                          displayType="text"
                          defaultValue="--"
                          value={value}
                          isNumericString="true"
                          format={formatKpiValue(key, value)}
                        />
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default FinancialsAndTraction;
