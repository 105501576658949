import { createSelector } from 'reselect';
import { PREVIEWERS } from '../../helpers/constants';
import _ from 'lodash';

const getUser = (state) => state.auth.user;
const getAuthResult = (state) => state.auth.authResult;
const getAuthAttempting = (state) => state.auth.attempting;
const getUserAttempting = (state) => state.auth.attemptingUser;
const getTraits = (state) => state.auth.traits;
const getIdentifyUserFailure = (state) => state.auth.errorCode;
const getExpiresIn = (state) => state.auth.expiresIn;
const getForceUserInfo = (state) => state.auth.forceGetUserInfo;
const getKycInfo = (state) => state.kycStatus;

export const getUserSelector = createSelector(getUser, (user) => user);
export const getAuthResultSelector = createSelector(getAuthResult, (authResult) => authResult);
export const getExpiresInSelector = createSelector(getExpiresIn, (expiresIn) => expiresIn);
export const getUserAttemptingSelector = createSelector(getUserAttempting, (attemptingUser) => attemptingUser);
export const getAuthAttemptingSelector = createSelector(getAuthAttempting, (attempting) => attempting);
export const getTraitsSelector = createSelector(getTraits, (traits) => traits);
export const getIdentifyUserFailureSelector = createSelector(getIdentifyUserFailure, (errorCode) => errorCode);
export const getForceUserInfoSelector = createSelector(getForceUserInfo, (forceGetUserInfo) => forceGetUserInfo);

export const getProviderSelector = createSelector(getUser, (user) => {
  if (!user?.identities?.length > 0) return null;

  return user.identities[0].provider;
});

export const getUserDetailsSelector = createSelector(getUser, (user) => {
  if (!user?.app_metadata) return false;

  return user.app_metadata;
});

export const getRolloverPopupSelector = createSelector(getTraits, (traits) => {
  return traits?.rolloverPopup;
});

export const getNeedsDetailsSelector = createSelector(getTraits, (traits) => {
  return traits?.needsDetails;
});

export const getNeedsAccreditationSelector = createSelector(getTraits, (traits) => {
  return traits?.needsAccreditation;
});

export const getNeedsVerifySelector = createSelector(getTraits, (traits) => {
  return traits?.emailVerified;
});

export const getIsPreviewerSelector = createSelector(getUser, (user) => {
  if (!user?.roles && !user?.email) {
    return null;
  }

  return _.includes(user?.roles, 'previewer') || PREVIEWERS.includes(user?.email);
});

export const getIsAdminSelector = createSelector(getUser, (user) => {
  if (!user?.roles) {
    return null;
  }

  return _.includes(user?.roles, 'admin');
});

export const getIsGuestSelector = createSelector(getUser, (user) => {
  return user === null || user === undefined;
});

export const getIsIncompleteKycSelector = createSelector(getKycInfo, (kycStatus) => {
  return kycStatus?.kycStatus?.some(
    (item) => item.status !== "submitted" && item.status !== "verified"
  ); 
});
