import React, { useCallback, useEffect, useState } from 'react';

// mui utils
import DialogContent from '@mui/material/DialogContent';
import MobileStepper from '@mui/material/MobileStepper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

// third party utils
import { useSelector, useDispatch } from 'react-redux';
import 'react-phone-input-2/lib/material.css';
import TagManager from 'react-gtm-module';
import _ from 'lodash';

// iAngels utils
import { EMAIL_MAX_LENGTH ,PROVIDERS} from '../../helpers/constants';
import { getOwner } from '../../helpers/iangels';
import { track, identify } from '../../services/analytics';
import Email from '../../assets/email.svg';

// Generics components
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import Popup from '../../components/Generic/Popup';
import ErrorAlert from '../../components/Generic/ErrorAlert';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';
// App container
import { identifyUserAttempt, clearUserState } from '../App/actions';
import { getUserSelector, getProviderSelector} from '../App/selectors';
// current container
import { identityRegisterAttempt, identitySubscribeAttempt, clearSignup } from './actions';
import RegisterComplete from './RegisterComplete';
import RegisterForm from './RegisterForm';

import {
  getErrorSelector,
  getIdentityRegisterAttemptingSelector,
  getIdentityRegisterSuccessSelector,
} from './selectors';

// json
import countries from '../../components/SharedData/countries.json';
// iangels utils
import { loadConfig } from '../../env/config_util';


const SignUpModel = () => {
  // hooks
  const isMobile = IsMobileDevice();
  const dispatch = useDispatch();
  const config = loadConfig();
  const marketing_website_base_url = config.config_module.marketing_website_base_url;

  // selectors
  const registerSuccess = useSelector(getIdentityRegisterSuccessSelector);
  const loading = useSelector(getIdentityRegisterAttemptingSelector);
  const error = useSelector(getErrorSelector);
  const user = useSelector(getUserSelector);
  const provider = useSelector(getProviderSelector);

  // state
  const [activeStep, setActiveStep] = useState(1);
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(true);
  const [phone, setPhone] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [subscribe, setSubscribe] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState(true);

  var firstName = '';
  var lastName = '';
  const fullName = /^[A-Za-z0-9\-_ ()/."\\]*$/.test(user.user_metadata.full_name) ? user.user_metadata.full_name : '';

  if (!(fullName === '')) {
    if (/ /.test(fullName)) {
      firstName = fullName.split(' ', 1)[0];
    } else {
      firstName = fullName;
    }
  } else {
    firstName = '';
  }

  lastName = /\s/.test(fullName) ? fullName.substr(firstName.length + 1) : '';

  useEffect(() => {
    if (registerSuccess && !showPopup) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [registerSuccess, dispatch, showPopup]);

  useEffect(() => {
    if (error) {
      setHasError(true);
      dispatch(clearSignup());
    }
  }, [error, dispatch]);

  const handleExplore = () => {
    setOpen(false);
    if(subscribe)
      dispatch(identitySubscribeAttempt());
    dispatch(clearSignup());
    dispatch(clearUserState());
    dispatch(identifyUserAttempt());
  };

  const handleAllowCommunicationPopup = () => {
    setUnsubscribe(false);
    dispatch(identitySubscribeAttempt());
    setShowPopup(false);
  }

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };

  const initialState = useCallback(
    () => ({
      firstName: firstName || '',
      lastName: lastName || '',
      city: '',
      state: '',
      country: _.find(countries, { code: 'IL' }) || '',
      countryCode: 'IL',
      accredited: 'false',
      allowCommunication: false,
      email: user?.email,
      stateCode: '',
    }),
    [user, firstName, lastName]
  );

  const [registerFormValues, setRegisterFormValues] = useState(initialState);

  useEffect(() => {
    setRegisterFormValues(initialState);
  }, [user, initialState]);

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    city: '',
    state: '',
    country: '',
    countryCode: '',
    allowCommunication: '',
    phone: '',
  });

  const validForm = () => {
    let newErrors = { ...errors };

    const firstNameValid = isValid(
      registerFormValues.firstName,
      (message) => (newErrors.firstName = message),
      'First name'
    );
    const lastNameValid = isValid(
      registerFormValues.lastName,
      (message) => (newErrors.lastName = message),
      'Last name'
    );

    let phoneValid = isPhoneValid(phone.replace(/[- )(]/g, ''), newErrors);

    setErrors(newErrors);

    if (
      !firstNameValid ||
      !lastNameValid ||
      !phoneValid
    )
      return false;
    return true;
  };

  const handleNext = () => {
    try {
      if (!validForm()) {
        track('Personal Details Validation Error', {
          category: 'Onboarding',
          // invalid_details: _.concat(invalidFields, self.getMissingDetails()) NEED TO IMPLEMENT THIS
        });
        return;
      }
      track('User Started Registration', { category: 'Onboarding' });
      let data = JSON.parse(JSON.stringify(registerFormValues));
      data.country = registerFormValues.countryCode ? registerFormValues.country.name : '';
      data.fullName = registerFormValues.firstName.trim() + ' ' + registerFormValues.lastName.trim();
      data.phone = phone.replace(/[- )(]/g, '');
      data.roles = ['investor'];
      data.owner = getOwner(registerFormValues.country?.name, registerFormValues.stateCode);
      delete data.firstName;
      delete data.lastName;
      delete data.state;

      if (data.accredited === 'true') {
        track('User Accredited', { category: 'Onboarding' });
        TagManager.dataLayer({ dataLayer: { event: 'user_accredited' } });
      } else {
        track('User Non-Accredited', { category: 'Onboarding' });
      }

      track('Personal Details Completed', { category: 'Onboarding' });
      track('User Registered', { category: 'Onboarding' });
      if(provider !== PROVIDERS.AUTH0)
        {
          track('Social User Registered', { category: 'Onboarding' });
          //this event trigger the pixel on google tag manger
          TagManager.dataLayer({ dataLayer: { event: 'user_registered' } });
        }
      dispatch(identityRegisterAttempt(data));

      identify(true);
      
      if (data.allowCommunication) {
        setUnsubscribe(false);
        track('User Subscribed', { category: 'Onboarding' });
      }
      else {
        setShowPopup(true);
      }
    } catch (e) {
      track('User Registration Error', { category: 'Onboarding' });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isPhoneValid = (number, newErrors) => {
    if (number.length < 7) {
      newErrors.phone = 'Phone number is required.';
      return false;
    }
    newErrors.phone = '';
    return true;
  };

  const isValid = (text, callback, fieldName) => {
    if (text.length === 0) {
      callback(`${fieldName} is required.`);
      return false;
    }

    if (text.trim().length > EMAIL_MAX_LENGTH) {
      callback(`${fieldName} is too long.`);
      return false;
    }

    if (!/^[A-Za-z0-9\-_ ()/."\\]*$/.test(text)) {
      callback(`Only English and special characters are allowed.`);
      return false;
    }

    callback('');
    return true;
  };

  const handleBackOfficeClick = () => {
    window.location.href = 'mailto:backoffice@iangels.com';
  };

  return (
      <Dialog
        //fullScreen={isMobile}
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { 
            display: "flex", 
            flexDirection: "column",
            maxWidth: activeStep === 1 ? "945px" :"745px",
            maxHeight: "740px",
            width: "90%",
            height: isMobile ? '100%': '70%',
            mx: "auto", // Center horizontally
            my: "auto", // Center vertically
            backgroundColor: 'transparent'
          }
        }}
        slotProps={{
          backdrop: {
            sx: { 
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(9, 78, 96, 0.97)' } // Semi-transparent #094E60 (95% opacity)
          }
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!isMobile && <DialogTitle
          id="responsive-dialog-title"
          style={{backgroundColor:'transparent', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <MobileStepper
            variant="progress"
            steps={3}
            position="static"
            activeStep={activeStep}
            style={{ width: isMobile ? 350 : 600 }}
            sx={{  flexGrow: 1}}
          
            className="stepper"
            nextButton={<Button><span className='stepperButton'>Finish</span></Button>}
            backButton={
              <Button size="small" onClick={handleBack}>
                <span className='stepperButton'>Start</span>
              </Button>
            }
          />
        </DialogTitle>}
        <DialogContent
          dividers={true}
          style={{ padding:"10px", display: 'flex', flexDirection: 'column', backgroundColor: 'white'}}
        >
          {activeStep === 1 ? 
            <div className={`f-f-tgf f-w-500 g4-c ${isMobile ? 'f-size-12' : 'f-size-16 pt-40 pb-5'}`} align='center'>Welcome to iAngels investment platform</div>
            : null
          }
          {loading ? (
            <LoadingIndicator />
          ) : activeStep === 1 ? (
            <div className="df-c fdr-c">
              <ErrorAlert
                style={{ maxWidth: 800 }}
                hasError={hasError}
                setHasError={setHasError}
                title=""
                text="Something went wrong and we couldn't complete your registration. Please contact our compliance officer at "
                extraText="backoffice@iangels.com"
                handleClick={handleBackOfficeClick}
                extraClasses="bold pointer"
              />
              <RegisterForm
                setValues={setRegisterFormValues}
                values={registerFormValues}
                errors={errors}
                setErrors={setErrors}
                setPhone={setPhone}
                phone={phone}
              />
            </div>
          ) : (
            <RegisterComplete setSubscribe={setSubscribe} unsubscribe={unsubscribe} isMobile={isMobile}/>
          )}

<div style={{ display: 'flex', justifyContent: 'center' }} className='mt-20'>
{!loading && (
              <Button
                variant="contained"
                className="btn form-btn"
                style={{
                  minWidth: activeStep === 1 ? '70%' : '90%',
                  minHeight: 50,
                  borderRadius: 0,
                  alignSelf: 'center',
                }}
                onClick={activeStep === 1 ? handleNext : handleExplore}
              >
                {activeStep === 1 ? 'Next' : 'Explore the iAngels platform'}
              </Button>
            )}
          </div>
          {activeStep === 1  && <div className='f-f-g f-w-400 f-size-10 g4-c mt-20' align='center'>By proceeding, I agree to the 
            <span className="g-c pointer"
              onClick={() => window.open(marketing_website_base_url + 'terms-of-service/', '_blank')}>
              &nbsp;Terms & Conditions&nbsp;
            </span>
            and&nbsp;
            <span
              className="g-c pointer"
              onClick={() => window.open(marketing_website_base_url + 'privacy-policy/', '_blank')}
            >
              Privacy Policy
            </span>.</div>}
            {showPopup && <Popup 
                        title='You choose not to receive investment opportunities' 
                        textAccept='I wish to receive investment opportunities'
                        textDecline='No, thanks'
                        popupClasses='popupSignUp'
                        onAccept={handleAllowCommunicationPopup}
                        onDecline={handleClosePopup} 
                        icon={<img src={Email} alt=""
                        borderClasses='border-top-iangels' />}/>}          
            
        </DialogContent>
      </Dialog>
  );
};

export default SignUpModel;