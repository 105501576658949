import React from 'react';

// iangels utils
import FounderWrapper from '../../components/FounderPage/FounderWrapper';
import Text from '../../components/text';
import Field from '../../components/FounderPage/Field';

const KeyQuestions = ({ keyQuestions, keyQuestionsError, handleKeyQuestionsChange, matches, title }) => {
  const addressableMarketField = {
    type: 'text-multiline',
    required: true,
    error: keyQuestionsError.addressableMarket,
    id: 'addressableMarket',
    label: 'What is the addressable market & market size?',
    value: keyQuestions.addressableMarket,
    name: 'addressableMarket',
    onChange: handleKeyQuestionsChange,
    helperText: keyQuestionsError.addressableMarket,
  };

  const problemField = {
    type: 'text-multiline',
    required: true,
    error: keyQuestionsError.problem,
    id: 'problem',
    label: 'What is the problem you are trying to solve?',
    value: keyQuestions.problem,
    name: 'problem',
    onChange: handleKeyQuestionsChange,
    helperText: keyQuestionsError.problem,
  };

  const landscapeField = {
    type: 'text-multiline',
    required: true,
    error: keyQuestionsError.landscape,
    id: 'landscape',
    label: "Define the company's competitive landscape?",
    value: keyQuestions.landscape,
    name: 'landscape',
    onChange: handleKeyQuestionsChange,
    helperText: keyQuestionsError.landscape,
  };

  const planField = {
    type: 'text-multiline',
    required: true,
    error: keyQuestionsError.plan,
    id: 'plan',
    label: 'What do you plan to achieve with the desired funding?',
    value: keyQuestions.plan,
    name: 'plan',
    onChange: handleKeyQuestionsChange,
    helperText: keyQuestionsError.plan,
  };

  const visionField = {
    type: 'text-multiline',
    required: true,
    error: keyQuestionsError.vision,
    id: 'vision',
    label: "What is the company's vision?",
    value: keyQuestions.vision,
    name: 'vision',
    onChange: handleKeyQuestionsChange,
    helperText: keyQuestionsError.vision,
  };

  const reasonField = {
    type: 'text-multiline',
    required: true,
    error: keyQuestionsError.reason,
    id: 'reason',
    label: 'Why iAngels?',
    value: keyQuestions.reason,
    name: 'reason',
    onChange: handleKeyQuestionsChange,
    helperText: keyQuestionsError.reason,
  };

  const sourceField = {
    type: 'text-multiline',
    required: true,
    error: keyQuestionsError.source,
    id: 'source',
    label: 'How did you hear about us?',
    value: keyQuestions.source,
    name: 'source',
    onChange: handleKeyQuestionsChange,
    helperText: keyQuestionsError.source,
  };

  return (
    <FounderWrapper matches={matches}>
      {' '}
      <Text variant="h6" classes="f-tgf-400 g4-c" text={title} />
      <Field obj={addressableMarketField}  />
      <Field obj={problemField} />
      <Field obj={landscapeField} />
      <Field obj={planField} />
      <Field obj={visionField} />
      <Field obj={reasonField} />
      <Field obj={sourceField} />
    </FounderWrapper>
  );
};

export default KeyQuestions;
