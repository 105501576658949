import React, { useEffect, useState, useCallback } from 'react';

// mui utils
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogActions } from '@mui/material';
import {  IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// iAngels utils
import { track} from '../../services/analytics';
import { rolloverPopupAttempt, setSelectedKYC,rolloverInvestmentsAttempt, setRolloversToInvest,setPopup } from './actions';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';
import { getUserSelector } from '../App/selectors';
import {getSigningUrlSelector,getInvestmentAttemptingSelector} from '../Invest/selectors';
import OpportunitiesSection from '../../components/RolloverPopup/OpportunitiesSection';
import HeaderPopup from '../../components/RolloverPopup/HeaderPopup';
import FooterPopup from '../../components/RolloverPopup/FooterPopup';
import {calculateUpdatedRollovers} from '../../helpers/iangels';

// third party utils
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/material.css';


import {
  getErrorSelector,
  getRolloverPopupAttemptingSelector,
  getRolloverPopupSuccessSelector,
  getRolloverEntitiesSelector,
  getSelectedKYCSelector,
  getFilteredRolloversSelector,
  getFilteredCashBalancesSelector,
  getRolloverPopupTotalsSelector,
  getErrorInvestSelector,
  getRolloverInvestmentSuccessSelector,
  getOpenPopupSelector
} from './selectors';

const RolloverPopup = () => {
  // hooks
  const isMobile = IsMobileDevice();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selectors
  const rolloverPopupSuccess = useSelector(getRolloverPopupSuccessSelector);
  const rolloverEntities = useSelector(getRolloverEntitiesSelector);
  const selectedKYC = useSelector(getSelectedKYCSelector);
  const filteredRollovers = useSelector(getFilteredRolloversSelector);
  const filteredCashBalances = useSelector(getFilteredCashBalancesSelector);
  const loading = useSelector(getRolloverPopupAttemptingSelector);
  const error = useSelector(getErrorSelector);
  const user = useSelector(getUserSelector);
  const { sumRollover, sumCashBalance, sumOther } = useSelector(getRolloverPopupTotalsSelector);

  const errorInvest = useSelector(getErrorInvestSelector);
  const rolloverInvestmentsSuccess = useSelector(getRolloverInvestmentSuccessSelector);
  const loadingInvestment = useSelector(getInvestmentAttemptingSelector);
  const successUrlInvestment = useSelector(getSigningUrlSelector);

  // state
  const open = useSelector(getOpenPopupSelector) ?? true;;
  // Set the first option as default
  const [selected, setSelected] = useState();

  //functions

  const handleSelectChange = useCallback((value) => {
    dispatch(setSelectedKYC(value));
    // setSelected(value);
    // console.log("selected in INDEX after handleSelectChange= "+selected);
  },[dispatch]);
  const handleClose= useCallback(() => {
    dispatch(setPopup(false));
  },[dispatch]);

  useEffect(() => {
    if (rolloverPopupSuccess) {
      if (!selected && !selectedKYC && rolloverEntities[0]) {
        setSelected(rolloverEntities[0]);
        //console.log("selected in INDEX after setSelected= "+selected);
        handleSelectChange(rolloverEntities[0]);
      }
    }
  }, [rolloverPopupSuccess, dispatch,selected,selectedKYC,rolloverEntities,handleSelectChange]);

  useEffect(() => {
    if (error) {
      console.log(error);
      navigate('/error'); 
    }
  }, [error, dispatch,navigate]);

  useEffect(() => {
    if (errorInvest) {
      handleClose();
      navigate('/error');
    }

    if (rolloverInvestmentsSuccess && !successUrlInvestment && !loadingInvestment) {
      handleClose();
      navigate(`/invest/esign/${rolloverInvestmentsSuccess.investmentType}/${rolloverInvestmentsSuccess.id}/is_true`);
    }
  }, [errorInvest, navigate, dispatch, rolloverInvestmentsSuccess,successUrlInvestment,loadingInvestment,handleClose]);

  useEffect(() => {
    if (user && !loading && open && (rolloverPopupSuccess===undefined || rolloverPopupSuccess===null) )  { 
      try {
        dispatch(rolloverPopupAttempt());
      } catch (e) {
        console.log(e);
      }
    }
  }, [user, loading,open,rolloverPopupSuccess,dispatch]);


  const calculateRolloversToInvest = (
    rollovers,
    cashBalances,
    sumRollover,
    sumCashBalance,
    sumOther
  ) => {
    let remainingTotal = sumRollover; // Start with rollover amount
    let remainingCashBalances = cashBalances.map((cash) => ({
      ...cash,
    }));// Clone cash balances
    let remainingCashTotal = sumCashBalance; // Total cash balance available
    let remainingOther = sumOther; // Remaining 'other' balance if needed
  
    const updatedRollovers = rollovers.filter((rollover) => rollover.amountToInvest !== 0) // Remove rollovers where amountToInvest is 0
      .map((rollover) => {
      // Initialize an array to track cash balance contributions
      let amountFromCashBalances = [];
      let amountToAdd = 0; 
      let remainingToInvest = rollover.amountToInvest; // Amount still needed for this rollover
      let amountRollovered=0;
  
      // Deduct from rollover balance first
      if (remainingTotal > 0) {
        if (remainingTotal >= remainingToInvest) {
          remainingTotal -= remainingToInvest; // Fully covered by rollover
          amountRollovered= remainingToInvest;
          remainingToInvest = 0;
        } else {
          // Partial coverage from rollover
          remainingToInvest -= remainingTotal;
          amountRollovered= remainingTotal;
          remainingTotal = 0; 
        }
      }
  
      // Use cash balances if rollover is insufficient
      while (remainingToInvest > 0 && remainingCashTotal > 0 && remainingCashBalances.length > 0) {
        const cash = remainingCashBalances[0]; 
  
        if (cash.amount >= remainingToInvest) {
          // Partial or full use of cash balance
          amountFromCashBalances.push({
            cashId: cash.cashId,
            amount: remainingToInvest,
            installmentId:cash.installmentId,
            secondarySaleId:cash.secondarySaleId
          });
          cash.amount -= remainingToInvest; 
          remainingCashTotal -= remainingToInvest;
          remainingToInvest = 0; 
        } else {
          // Use entire cash balance and continue
          amountFromCashBalances.push({
            cashId: cash.cashId,
            amount: cash.amount, 
            installmentId:cash.installmentId,
            secondarySaleId:cash.secondarySaleId
          });
          remainingToInvest -= cash.amount; 
          remainingCashTotal -= cash.amount;
          remainingCashBalances.shift(); // Remove fully consumed cash balance
        }
      }
  
      // Use "Other" funds if both rollover and cash balances are exhausted
      if (remainingToInvest > 0 && remainingOther > 0) {
        amountToAdd = Math.min(remainingOther, remainingToInvest); 
        remainingOther -= amountToAdd;
        remainingToInvest -= amountToAdd;
      }
  
      return {
        ...rollover,
        amountRollovered,
        amountFromCashBalances,
        amountToAdd, 
      };
    });
  
    return updatedRollovers;
  };
  

  const handleInvest=()=>{

    let updated=[];
    if (selectedKYC==="All")
    {
      rolloverPopupSuccess.forEach((roll) => {
        let sumRoll=roll.rollovers.reduce((total, rollover) => total + (rollover.amountWithFees|| 0), 0);
        let sumCash=roll.cashBalances.reduce((total, cash) => total + (cash.amount || 0), 0);
        const finalRollovers = calculateUpdatedRollovers(roll.rollovers, sumRoll + sumCash);
        updated=updated.concat(calculateRolloversToInvest(finalRollovers,roll.cashBalances,sumRoll,sumCash,0));
      })
     
    }
    else{
      const cashBalancesForInvest=rolloverPopupSuccess.find(obj => obj.entity === selectedKYC).cashBalances
      updated = calculateRolloversToInvest(filteredRollovers,cashBalancesForInvest,sumRollover,sumCashBalance,sumOther);
    }
    track('Rollover investment platform');
    dispatch(setRolloversToInvest(updated));
    dispatch(rolloverInvestmentsAttempt(updated[0]));
   

  }

  

  return (
    <>
      {loading ||loading===undefined || rolloverPopupSuccess?.length<1 || rolloverPopupSuccess == null ? null :
      <Dialog fullScreen={isMobile} open={open} aria-labelledby="responsive-dialog-title" maxWidth={false} // Disable predefined breakpoints
      PaperProps={{
        style: { maxWidth: '666px' ,minWidth:isMobile?'0':'666px'},
      }} scroll="paper" >
        <DialogTitle id="responsive-dialog-title" className="g4-bg " sx={{paddingBottom:0}}>
          <HeaderPopup
            cashBalances={filteredCashBalances}
            entitiesList={rolloverEntities}
            rolloverStartupFrom={rolloverPopupSuccess[0].installmentName}
            onSelectChange={handleSelectChange}
            selected={selectedKYC}
            
          />
          <IconButton className="w-c" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers={true}
          className="rollover-popup"
          style={{ display: 'flex', flexDirection: 'column',justifyContent: 'stretch'  }}
        >
          <OpportunitiesSection rollovers={filteredRollovers} />
        </DialogContent>
        <DialogActions>
          <FooterPopup sumRollover={sumRollover} sumCashBalance={sumCashBalance} sumOther={sumOther} onInvest= {handleInvest}/>
        </DialogActions>
      </Dialog>}
    </>
  );
};

export default RolloverPopup;
