import React from 'react';
import { convertToInternationalCurrencySystem, dateToTextFormat, humanize } from '../helpers/iangels';
import Text from './text';

const CapitalPositionCard = ({ name, date, value }) => {
  return (
    <div className="d-f a-i-c j-c-sb capital-position-card mt-10">
      <div>
        <Text classes="f-f-tgf f-w-500 f-size-12 capitalize" text={humanize(name)} />
        <Text classes="f-f-g f-w-300 f-size-12 mt-5 " text={dateToTextFormat(date)} />
      </div>
      <Text classes="f-f-tgf f-w-500 f-size-16 g7-c ml-30" text={value} />
    </div>
  );
};

const CapitalPositionTitle = ({ title, isMobile }) => {
  return <Text classes={`f-f-tgf f-w-500 f-size-14 g4-c ${isMobile ? 'mt-10' : 'mt-20'}`} text={title} />;
};

const CapitalPosition = ({
  cashOnHand,
  monthlyNetBurnRate,
  operatingCashFlow,
  isMobile,
}) => {

  const getLastDayOfSecondLastQuarter = () => {
    const now = new Date();
    const currentQuarter = Math.floor((now.getMonth() / 3)) + 1;
    let year = now.getFullYear();
    let quarter = currentQuarter - 2;
  
    if (quarter <= 0) {
      quarter += 4;
      year -= 1;
    }
  
    const lastMonthOfQuarter = quarter * 3;
    return new Date(year, lastMonthOfQuarter, 0); // Day 0 gets the last day of the previous month
  };
  
  const isKpiNegative = (value) => {
    return value < 0;
  };

  const getKpiText = (value) => {
    let res = `$${convertToInternationalCurrencySystem(value, 1)}`
    return (isKpiNegative(value)) ?`(${res})` : res;
  }

  const lastDayOfSecondLastQuarter = getLastDayOfSecondLastQuarter();
  const displayCashOnHand = cashOnHand && new Date(cashOnHand.date) >= lastDayOfSecondLastQuarter;
  const displayMonthlyNetBurnRate = monthlyNetBurnRate && new Date(monthlyNetBurnRate.date) >= lastDayOfSecondLastQuarter;
  const displayOperatingCashFlow = operatingCashFlow && new Date(operatingCashFlow.date) >= lastDayOfSecondLastQuarter;
  const displayCapitalPosition = displayCashOnHand || displayMonthlyNetBurnRate || displayOperatingCashFlow;  
  
  return (
    <>
    {displayCapitalPosition && <>
      <CapitalPositionTitle title="Capital Position" isMobile={isMobile} />
      <div style={{ display: 'flex', flexWrap: 'wrap' }} className="fdr-c">
        {displayCashOnHand && (
          <CapitalPositionCard
            name={humanize(cashOnHand.type)}
            date={cashOnHand.date}
            value={getKpiText(cashOnHand.value)}
          />
        )}
        {displayMonthlyNetBurnRate &&  (
          <CapitalPositionCard
            name={monthlyNetBurnRate.type}
            date={monthlyNetBurnRate.date}
            value={getKpiText(monthlyNetBurnRate.value)}
          />
        )}
        {displayOperatingCashFlow && (
          <CapitalPositionCard
            name={operatingCashFlow.type}
            date={operatingCashFlow.date}
            value={getKpiText(operatingCashFlow.value, 1)}
          />
        )}
      </div>
    </>
  }
  </>
  );
};

export default CapitalPosition;
