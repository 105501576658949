import { identityRegisterFailure, identityRegisterSuccess, identitySubscribeFailure, identitySubscribeSuccess } from './actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { postData } from '../../services/apiGateway';
import { IDENTITY_REGISTER_ATTEMPT, IDENTITY_SUBSCRIBE_ATTEMPT } from './actionTypes';

function* registerAttempt(action) {
  try {
    const register = yield call(postData, '/iangels/users/identity/register', action.data);
    yield put(identityRegisterSuccess(register?.data));
  } catch (errorCode) {
    yield put(identityRegisterFailure(errorCode.response || errorCode));
  }
}

function* subscribeAttempt(action) {
  try {
    const subscribe = yield call(postData, '/iangels/users/identity/subscribe');
    yield put(identitySubscribeSuccess(subscribe?.data));
  } catch (errorCode) {
    yield put(identitySubscribeFailure(errorCode.response || errorCode));
  }
}

function* identityRegisterSaga() {
  yield all([takeLatest(IDENTITY_REGISTER_ATTEMPT, registerAttempt)]);
  yield all([takeLatest(IDENTITY_SUBSCRIBE_ATTEMPT, subscribeAttempt)]);
}

export default identityRegisterSaga;
