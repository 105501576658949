import React, { useEffect } from 'react';

// // mui utils
// import Button from '@mui/material/Button';
// import Paper from '@mui/material/Paper';

// iAngels utils
import { track } from '../../services/analytics';
//import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import Dashboard from '../Dashboard';
import { setPopup } from './actions';
import { refreshTraitsAttempt } from '../App/actions';

// third party utils
import { useDispatch } from 'react-redux';
//import { useNavigate } from 'react-router-dom';


const OpenRolloverPopup = () => {
    const dispatch = useDispatch();
    //const navigate = useNavigate();

    useEffect(() => {
        //in order to get current state of user.rollover_popup status
        track('Rollover page load');
        dispatch(refreshTraitsAttempt({}));
        dispatch(setPopup(true));
    }, [ dispatch]);
    return (
      //  <></>
    //     <Paper
    //     elevation={3}
    //     style={{
    //       maxWidth: 700,
    //       borderRadius: 0,
    //       padding: '60px 20px',
    //       display: 'flex',
    //       flexDirection: 'column',
    //       alignItems: 'center',
    //     }}
    //   >
    //     <div className="mt-20" style={{ display: 'flex' }}>
    //     <Button
    //       variant="contained"
    //       className="btn f-size-12 f-w-500"
    //       style={{ marginTop: 15, marginLeft: 10, minWidth: 120, minHeight: 40, borderRadius: 6, marginRight: 10 }}
    //       onClick={() => navigate('/dashboard')}
    //     >
    //       My Portfolio
    //     </Button>
       
    //   </div>
    //   </Paper>
        <Dashboard />
    );
};

export default OpenRolloverPopup;