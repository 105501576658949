import React from 'react';

import Stack from '@mui/material/Stack';
import Field from './Field';

const TwoFieldStack = ({fieldAObject, fieldBObject }) => {
  return (
    <Stack direction={{xs:'column', sm: 'row'}} spacing={{ xs: 1, sm: 2, md: 4 }} alignItems="center" >
      {fieldAObject ? (
         <Field obj={fieldAObject} />
      ) : null}
      {fieldBObject ? (
        <Field obj={fieldBObject} />
      ) : null}
    </Stack>
  );
};

export default TwoFieldStack;
