import React, { useEffect, useLayoutEffect, useState, useMemo } from 'react';
import StickyBox from 'react-sticky-box';

// iangels utils
import { INVESTMENTS_SIDE_NAV } from '../../helpers/constants';
import { track } from '../../services/analytics';

import SignNda from '../../components/SignNda';

import ScrollToTop from '../../components/Generic/ScrollToTop';

//sections
import MemoWrapper from '../../components/Memo/MemoWrapper';
import MemoTitle from '../../components/Memo/MemoTitle';
import MyInvestments from './MyInvestments';
import MemoCompanySnapshot from '../../components/Memo/MemoCompanySnapshot';
import MemoCompanyPortfolio from '../../components/Memo/MemoCompanyPortfolio';
import MemoAudio from '../../components/Memo/MemoAudio';
import MemoTeam from '../../components/MemoTeam';

// mui utils
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

// third party utils
import { getStartupPageSelector } from './selectors';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CompanyPageDesktop = (props) => {
  const { slug, matches } = props;
  const navigate = useNavigate();

  const startupPage = useSelector(getStartupPageSelector);

  const hasSnapshot = () => {
    return startupPage?.keyBusinessUpdates?.length > 0 && startupPage?.snapshotIsVisible && startupPage?.isUserVerified;
  };

  const hasUpdates = () => {
    return startupPage?.updates?.length > 0 && !startupPage?.snapshotIsVisible;
  };

  // state
  const [isNdaModalShown, setIsNdaModalShown] = useState(false);
  const limitedView = startupPage.isUserRestricted || !startupPage.isUserVerified;
  const [showMemo, setShowMemo] = useState(startupPage.meta.fundingStatus === 'open' && !limitedView);
  const [ids, setIds] = useState([]);
  const [selected, setSelected] = useState(
    startupPage.investments?.length > 0
      ? INVESTMENTS_SIDE_NAV.myInvestments
      : hasSnapshot() || hasUpdates()
        ? INVESTMENTS_SIDE_NAV.companySnapshot
        : startupPage.companyOverview?.title
  );

  const isNeedToSignNda = useMemo(() => {
    return (
      startupPage.openRound &&
      !startupPage.openRound.noNda &&
      !startupPage.openRound.ndaSigned &&
      !startupPage.openRound.additionalMaterials &&
      startupPage.openRound.investmentMemoParts.filter((part) => part.content === "hidden").length > 0 &&
      showMemo
    );
  }, [startupPage, showMemo]);

  const isPreviousInvestmentThesis = useMemo(() => {
    return !startupPage.showMemo && startupPage.openRound && startupPage.meta.fundingStatus === 'closed';
  }, [startupPage]);

  useLayoutEffect(() => {
    const listener = () => {
      const clamp = (value) => Math.max(0, value);

      const isBetween = (value, floor, ceil) => value >= floor && value <= ceil;
      const scroll = window.pageYOffset;
      const offset = 450;
      const positions = ids.map((id) => {
        const element = document.getElementById(id);

        if (!element) return { id, top: -1, bottom: -1 };

        const rect = element.getBoundingClientRect();
        const top = clamp(rect.top + scroll - offset);
        const bottom = clamp(rect.bottom + scroll - offset);

        return { id, top, bottom };
      });
      const fixedPositions = positions.map(function (value, index, elements) {
        const next = elements[index + 1];
        if (value?.bottom > next?.top) value.bottom = next.top - 1;
        return value;
      });
      const position = fixedPositions.find(({ top, bottom }) => isBetween(scroll, top, bottom));
      if (position !== undefined && position?.id !== '' && selected !== position?.id) {
        setSelected(position?.id);
      }
    };
    listener();

    window.addEventListener('resize', listener);
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('resize', listener);
      window.removeEventListener('scroll', listener);
    };
  });

  useEffect(() => {
    if (document.readyState === 'complete') {
      setIds([...document.getElementsByClassName('nav-item')].map((o) => o.value));
    }
  }, []);

  useEffect(() => {
    let mounted = true;

    const timer = setTimeout(() => {
      mounted && isNeedToSignNda && setIsNdaModalShown(true);
    }, 10);
    return () => {
      clearTimeout(timer);
      mounted = false;
    };
  }, [isNeedToSignNda]);

  const canSeePublicOpenRound = () => {
    return startupPage.openRound?.investmentMemoParts?.length > 0 && !limitedView;
  };

  const displayPrivate = (memo) => {
    return (memo.isPrivate === true && ((canSeePublicOpenRound() &&
      showMemo &&
      isPreviousInvestmentThesis) || !isPreviousInvestmentThesis)) || (startupPage.openRound && startupPage.openRound?.additionalMaterials);
  }

  const displayPublic = (memo) => {
    return !memo.isPrivate && showMemo;
  }
  const handleNdaClose = () => {
    setIsNdaModalShown(false);
  };

  const handleSidenav = (event, newSelected) => {
    setSelected(newSelected);

    const element = document.getElementById(newSelected);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      // element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      const yOffset = -60;

      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window[`scrollTo`]({ top: y, behavior: 'smooth' });
      track('Click On TOC', { category: 'Startup', area: 'Item', item: event?.target?.value });
    }
  };

  const StyledToggleButton = styled(ToggleButton)({
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#d8eef0',
      borderLeft: '2px solid #4cbcbf !important',
      color: '#094e60',
      fontWeight: 600,
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <ScrollToTop showBelow={250} />
      {/* side nave section */}
      <StickyBox className="sticky-side-nav">
        <MemoAudio startupPage={startupPage} limitedView={limitedView} />
        <ToggleButtonGroup value={selected} orientation="vertical" exclusive onChange={handleSidenav}>
          {startupPage.investments?.length > 0 ? (
            <StyledToggleButton className="nav-item" value={INVESTMENTS_SIDE_NAV.myInvestments}>
              {INVESTMENTS_SIDE_NAV.myInvestments}
            </StyledToggleButton>
          ) : null}
          {hasSnapshot() || hasUpdates() ? (
            <StyledToggleButton className="nav-item" value={INVESTMENTS_SIDE_NAV.companySnapshot}>
              {INVESTMENTS_SIDE_NAV.companySnapshot}
            </StyledToggleButton>
          ) : null}
          {startupPage.arbeFireside ? (
            <StyledToggleButton className="nav-item" value="part-arbe-fireside">
              {startupPage.arbeFireside.title}
            </StyledToggleButton>
          ) : null}
          {startupPage.companyOverview ? (
            <StyledToggleButton className="nav-item" value={startupPage.companyOverview.title}>
              {startupPage.companyOverview.title}
            </StyledToggleButton>
          ) : startupPage.description ? (
            <StyledToggleButton className="nav-item" value={INVESTMENTS_SIDE_NAV.description}>
              {INVESTMENTS_SIDE_NAV.description}
            </StyledToggleButton>
          ) : null}

          {!showMemo && isPreviousInvestmentThesis ? (
            <StyledToggleButton
              className="nav-item nav-item-menu"
              value={INVESTMENTS_SIDE_NAV.PreviousInvestmentThesis}
              onClick={() => setShowMemo(true)}
            >
              {INVESTMENTS_SIDE_NAV.PreviousInvestmentThesis}
            </StyledToggleButton>
          ) : (
            startupPage?.openRound?.investmentMemoParts.map((memo) => (
              (displayPublic || displayPrivate) ?
                <StyledToggleButton className="nav-item" key={memo.title} value={memo.title}>
                  {memo.title}
                </StyledToggleButton>
                : null
            ))
          )
          }
          {startupPage.portfolio?.length > 0 ? (
            <StyledToggleButton className="nav-item" value={INVESTMENTS_SIDE_NAV.portfolio}>
              {INVESTMENTS_SIDE_NAV.portfolio}
            </StyledToggleButton>
          ) : null}
          {startupPage.teamMembers?.length > 0 ? (
            <StyledToggleButton className="nav-item" value={INVESTMENTS_SIDE_NAV.founders}>
              {INVESTMENTS_SIDE_NAV.founders}
            </StyledToggleButton>
          ) : null}
          {startupPage.latestLeadInvestors?.length > 0 ? (
            <StyledToggleButton className="nav-item" value={INVESTMENTS_SIDE_NAV.notableShareholders}>
              {INVESTMENTS_SIDE_NAV.notableShareholders}
            </StyledToggleButton>
          ) : null}
        </ToggleButtonGroup>
      </StickyBox>

      <Box className="max-width-memo company-content">
        {/* memo section */}
        {/* investment table */}
        {startupPage.investments?.length > 0 ? (
          <MemoWrapper id={INVESTMENTS_SIDE_NAV.myInvestments}>
            <MyInvestments investments={startupPage.investments} />
          </MemoWrapper>
        ) : null}
        {/* company Snapshot */}
        {hasSnapshot() || hasUpdates() ? (
          <MemoCompanySnapshot
            keyBusinessUpdates={startupPage.keyBusinessUpdates}
            monthlyNetBurnRate={startupPage.monthlyNetBurnRate}
            operatingCashFlow={startupPage.operatingCashFlow}
            snapshotIsVisible={startupPage.snapshotIsVisible}
            isUserVerified={startupPage.isUserVerified}
            cashOnHand={startupPage.cashOnHand}
            updates={startupPage.updates}
            kpis={startupPage.kpis}
            monthlySnapshotIsAvailable={startupPage.monthlySnapshotIsAvailable}
            defaultPeriod={startupPage.defaultPeriod}
          />
        ) : null}
        {startupPage.arbeFireside ? (
          <MemoWrapper
            id="part-arbe-fireside"
            title={startupPage.arbeFireside.title}
            contentHtml={startupPage.arbeFireside.content}
          />
        ) : null}
        {/* companyOverview / description */}
        {startupPage.companyOverview || startupPage.description ? (
          <MemoWrapper
            id={startupPage.companyOverview ? startupPage.companyOverview.title : INVESTMENTS_SIDE_NAV.description}
            title={startupPage.companyOverview ? startupPage.companyOverview.title : INVESTMENTS_SIDE_NAV.description}
            contentHtml={startupPage.companyOverview ? startupPage.companyOverview.content : startupPage.description}
            classesContentHtml={!startupPage?.isUserAccredited ? 'limited-content' : ' '}
          >
            {!startupPage?.isUserAccredited ? (
              <div className="limited-view-nonaccredited lh-1-5">
                You are currently in limited viewing mode as you signed up as a non accredited investor. If you are an
                accredited investor, please update{' '}
                <a href="/accreditation" underline="hover" className="g-c">
                  here
                </a>{' '}
                your accreditation status
                <div id="statement"> You will be asked to share accreditation credentials</div>
              </div>
            ) : null}
            {/* public & private memo */}
            {
              startupPage?.openRound?.investmentMemoParts.map((memo, index) => (
                (displayPublic || displayPrivate) ?
                  memo.content === "hidden" ?
                    <Paper
                      id={memo.title}
                      elevation={3}
                      style={{ borderRadius: 0 }}
                      className="nda-section mb-10"
                    >
                      <div className="nda-text-container">
                        <MemoTitle id={memo.title} title={memo.title} />
                        Supplemental information including deal terms and financial information
                        <strong>requires a confidentiality agreement.</strong>
                      </div>
                      <Button
                        variant="contained"
                        className="btn-sign-nda f-f-gl"
                        onClick={() => {
                          track('NDA Section Button Click', { category: 'Startup', startup: slug });
                          navigate(`/startup/${slug}/nda`);
                        }}
                      >
                        Sign NDA
                      </Button>
                    </Paper> :
                    <MemoWrapper id={memo.title} key={index} subTitle={memo.title} contentHtml={memo.content} /> : null
              ))}

          </MemoWrapper>
        ) : null}
        {/*  portfolio */}
        {startupPage.portfolio?.length > 0 ? (
          <MemoCompanyPortfolio
            id={INVESTMENTS_SIDE_NAV.portfolio}
            title={INVESTMENTS_SIDE_NAV.portfolio}
            portfolio={startupPage.portfolio}
          />
        ) : null}
        {/*  team Members */}
        {startupPage.teamMembers?.length > 0 ? (
          <MemoTeam
            title={INVESTMENTS_SIDE_NAV.founders}
            id={INVESTMENTS_SIDE_NAV.founders}
            matches={matches}
            investors={startupPage.teamMembers}
          />
        ) : null}
        {/* latest lead investors */}
        {startupPage.latestLeadInvestors?.length > 0 ? (
          <MemoTeam
            id={INVESTMENTS_SIDE_NAV.notableShareholders}
            title={INVESTMENTS_SIDE_NAV.notableShareholders}
            matches={matches}
            investors={startupPage.latestLeadInvestors}
          />
        ) : null}
      </Box>
      {/* sign nda modal */}
      <SignNda slug={slug} isNdaModalShown={isNdaModalShown} handleClose={handleNdaClose} />
    </Box>
  );
};

export default CompanyPageDesktop;
