import { call, all, put, takeLatest } from 'redux-saga/effects';
import { getDataFromService, postData } from '../../services/apiGateway';
import {
  investmentSuccess,
  investmentFailure,
  getInvestmentEntitiesFailure,
  getInvestmentEntitiesSuccess,
  getSigningEmbeddedUrlFailure,
  getSigningEmbeddedUrlSuccess,
} from './actions';

import { INVESTMENT_ATTEMPT, GET_INVESTMENT_ENTITIES_ATTEMPT, GET_SIGNING_EMBEDDED_URL_ATTEMPT } from './actionTypes';

function* investmentEntitiesAttempt(action) {
  try {
    const startupPage = yield call(getDataFromService, '/iangels/investments/user-entities');
    yield put(getInvestmentEntitiesSuccess(startupPage?.data));
  } catch (errorCode) {
    yield put(getInvestmentEntitiesFailure(errorCode.response || errorCode));
  }
}

function* investmentAttempt(action) {
  try {
    const update = yield call(postData, '/iangels/investments/investment', action.data);
    yield put(investmentSuccess(update?.data));
  } catch (errorCode) {
    yield put(investmentFailure(errorCode.response || errorCode));
  }
}

function* signingUrlAttempt(action) {
  try {
    const signingUrl = yield call(
      getDataFromService,
      `/iangels/investments/investment/${action.data.investmentType}/${action.data.investmentId}/${action.data.isRollover}/signing-embed-url`
    );
    yield put(getSigningEmbeddedUrlSuccess(signingUrl?.data));
  } catch (errorCode) {
    yield put(getSigningEmbeddedUrlFailure(errorCode.response || errorCode));
  }
}

function* investmentSaga() {
  yield all([takeLatest(INVESTMENT_ATTEMPT, investmentAttempt)]);
  yield all([takeLatest(GET_INVESTMENT_ENTITIES_ATTEMPT, investmentEntitiesAttempt)]);
  yield all([takeLatest(GET_SIGNING_EMBEDDED_URL_ATTEMPT, signingUrlAttempt)]);
}

export default investmentSaga;
