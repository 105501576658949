import React, { useEffect, useState } from 'react';

// iangels utils
import { getInvestmentEntitiesAttempt, investmentAttempt, clearInvestmentState } from './actions';
import InvestEntitySelectionMobile from '../../components/KYC/InvestEntitySelectionMobile';
import InvestEntitySelection from '../../components/KYC/InvestEntitySelection';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

import {
  getInvestmentEntitiesSelector,
  getInvestmentAttemptingSelector,
  getInvestmentErrorSelector,
  getInvestmentSuccessSelector,
} from './selectors';

// third party utils
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const Invest = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const isMobile = IsMobileDevice();

  const navigate = useNavigate();
  const [entity, setEntity] = useState('');

  const error = useSelector(getInvestmentErrorSelector);
  const entities = useSelector(getInvestmentEntitiesSelector);
  const investmentSuccess = useSelector(getInvestmentSuccessSelector);

  const loading = useSelector(getInvestmentAttemptingSelector);

  const handleNext = () => {
    const data = {
      currency: params?.currency || null,
      investmentAmount: params?.amount,
      name: typeof entity === 'string' ? entity : entity.name,
      roundId: params?.currentRoundId,
    };

    dispatch(investmentAttempt(data));
  };

  const isDisabled = () => {
    return !entity;
  };

  useEffect(() => {
    dispatch(getInvestmentEntitiesAttempt());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearInvestmentState());
      navigate('/error');
    }

    if (investmentSuccess) {
      dispatch(clearInvestmentState());
      navigate(`/invest/esign/${investmentSuccess.investmentType}/${investmentSuccess.id}/is_false`);
    }
  }, [error, navigate, dispatch, investmentSuccess]);

  const handleEntityChange = (e) => {
    setEntity(e.target.value);
  };

  const handleEntityKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleNext();
    }
  };

  return (
    <>
      {isMobile ? (
        <InvestEntitySelectionMobile
          params={params}
          handleEntityChange={handleEntityChange}
          handleNext={handleNext}
          isDisabled={isDisabled}
          loading={loading}
          entity={entity}
          setEntity={setEntity}
          entities={entities}
        />
      ) : (
        <InvestEntitySelection
          params={params}
          handleEntityChange={handleEntityChange}
          handleEntityKeyDown={handleEntityKeyDown}
          handleNext={handleNext}
          isDisabled={isDisabled}
          loading={loading}
          entity={entity}
          setEntity={setEntity}
          entities={entities}
        />
      )}
    </>
  );
};

export default Invest;
