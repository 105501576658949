import React from 'react';
import { MenuItem, Select, FormControl , Grid,Typography} from "@mui/material";
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

const KYCSelection = ({ entities, onSelectChange, selected }) => {
  // Insert "All" as the first option if the list has more than one item
  const handleChange = (event) => {
    onSelectChange(event.target.value); // Notify the parent about the change
  };
  const isMobile = IsMobileDevice();
  return (
    
    <Grid container width="inherit"  direction={isMobile ? 'column' : 'row'} sx={ isMobile ?  {}:{borderBottom: 1,
      borderColor: "white",
      paddingBottom: 1,
      marginBottom: 1, 
       }} >
    <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
      <Typography className="f-popup-headers">
        Choose KYC investment entity:
      </Typography>
    </Grid>

    <Grid item xs={12} md={6}>
    <FormControl fullWidth sx={{justifyContent: "center" }} >
      <Select 
        value={selected}
        onChange={handleChange}
        className="w-c f-f-gl f-w-700 f-size-14 lh-16"
        sx={{
          color:"white",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "white", // White border for outlined style
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white", // White border on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white", // White border when focused
          },
          "& .MuiSelect-icon": {
            color: "white", // White color for the dropdown arrow icon
          },
          height: "90%", // Make the input field 80% of its default height
          "& .MuiOutlinedInput-input": {
            padding: "6.5px 12px", // Adjust padding if needed
            height: "90%", // Reduce inner height to match the outer shrink
            fontWeight: "bold!important",
          },
        }}
      >
        {entities.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    </Grid>
    </Grid>
  );
};

export default KYCSelection;