import React, { useEffect } from 'react';

// material ui utils
import Container from '@mui/material/Container';

// iangels utils
import { getStartupPageSelector, getStartupPageAttemptingSelector, getStartupPageErrorSelector } from './selectors';
import { clearStartupPageError, getStartupPageAttempt } from './actions';
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import CompanyPageHeader from './CompanyPageHeader';
import CompanyPage from './CompanyPage';
import Affirmation from '../Affirmation';

// third party lib
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const StartupPage = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const loading = useSelector(getStartupPageAttemptingSelector);
  const startupPage = useSelector(getStartupPageSelector);
  const hasError = useSelector(getStartupPageErrorSelector);

  useEffect(() => {
    dispatch(getStartupPageAttempt(params?.slug, props.isChild));
  }, [params, dispatch, props.isChild]);

  useEffect(() => {
    if (hasError) {
      dispatch(clearStartupPageError());
      navigate('/error');
    }
  }, [navigate, dispatch, hasError]);

  return (
    <Container
      style={{
        padding: 0,
        marginTop: 40,
      }}
    >
      {loading ? (
        <LoadingIndicator />
      ) : startupPage ?  (
        <>
          {startupPage.needsAffirmation ? <Affirmation /> : null}
          <CompanyPageHeader
            logo={startupPage.logo}
            tagLine={startupPage.tagLine}
            sector={startupPage.sector}
            name={startupPage.name}
            investOptions={startupPage.investOptions}
            hideMinimum={startupPage.hideMinimum}
            currentRoundId={startupPage.currentRoundId}
            isFund={startupPage.isFund}
            canInvest={startupPage.meta.canInvest}
            isUserRestricted={startupPage.isUserRestricted}
            isUserVerified={startupPage.isUserVerified}
            isUserAccredited={startupPage.isUserAccredited}
            userCannotInvest={startupPage.userCannotInvest}
            isRollover={props.isRollover}
          />
          <CompanyPage slug={params.slug} />
        </>
      ):null}
    </Container>
  );
};

export default StartupPage;
