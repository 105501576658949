import React, { useCallback } from 'react';

// mui utils
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import  TextFieldForm from '../../components/SignUpModel/TextFieldForm';

import { ACCREDITATION_PAGES } from '../../helpers/constants';

// third party lib
import PhoneInput from 'react-phone-input-2';
import _ from 'lodash';

// json
import countries from '../../components/SharedData/countries.json';
import states from '../../components/SharedData/states.json';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

// styles
import 'react-phone-input-2/lib/material.css';

const RegisterForm = ({
  setValues,
  values,
  errors,
  setErrors,
  setPhone,
  phone,
}) => {
  const isMobile = IsMobileDevice();

  const handleChange = useCallback(
    (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
      if (!/^[A-Za-z0-9\-_ ()/."\\]*$/.test(event.target.value)) {
        setErrors({
          ...errors,
          [event.target.name]: `Only English and special characters are allowed.`,
        });
      } else {
        setErrors({
          ...errors,
          [event.target.name]: '',
        });
      }
    },
    [values, setValues, errors, setErrors]
  );

  const handleCountryChange = (event) => {
    setCountryChange(event.target.value);
  };

  const setCountryChange = useCallback(
    (inputCountry) => {
      setValues({
        ...values,
        country: inputCountry,
        countryCode: inputCountry.code || '',
        stateCode: '',
      });
    },
    [values, setValues]
  );

  const handleStateChange = (event) => {
    setValues({
      ...values,
      state: event.target.value,
      stateCode: event.target.value.abbreviation,
    });
  };

  const handleReceiveEmailChange = (event) => {
    setValues({
      ...values,
      allowCommunication: event.target.checked,
    });
  };

  const handlePhoneChange = useCallback(
    (value, _data, _e, formattedValue) => {
      const country = _.find(countries, { code: _data?.countryCode?.toUpperCase() });
      if (_e.target?.nodeName !== 'INPUT') {
        setCountryChange(country);
      }
      setPhone(formattedValue);
    },
    [setCountryChange, setPhone]
  );

  const handleAccreditationChange = (event) => {
    setValues({
      ...values,
      accredited: event.target.checked ? 'true' : 'false',
    });
  };

  const hasState = values.country?.code === 'US';

  const getAccreditationText = useCallback(() => {
    if (!values.country?.code) {
      return <span>I am an accredited Investor under the securities laws and regulations of my country</span>;
    }
    if (ACCREDITATION_PAGES.indexOf(values.country.code) !== -1) {
      return (
        <span className="justify">
          I meet the
          <span className="g-c pointer" onClick={() => window.open(`/accreditation/${values.country?.code}`, '_blank')}>
            {' '}
            accreditation requirements{' '}
          </span>
          in {values.country.name}
        </span>
      );
    }
    return (
      <span className="justify">
        I am an accredited Investor under the securities laws and regulations of {values.country.name}
      </span>
    );
  }, [values.country]);

  return (
    <div direction='column' className={`f-g-400 g4-c ${isMobile ? 'f-size-12' : 'f-size-14'}`} 
    style={{justifyContent:'center' , display:'flex', flexWrap:'wrap' }}>
      <Stack direction={isMobile?'column':'row'}>
        <TextFieldForm
          required={true}
          id="firstName"
          name="firstName"
          placeholder="First Name*"
          autoComplete="firstName"
          onChange={handleChange}
          value={values.firstName}
          error={errors.firstName !== ''}
          helperText={errors.firstName}
        />
        <TextFieldForm
          required={true}
          id="lastName"
          name="lastName"
          placeholder='Last Name*'
          value={values.lastName}
          autoComplete="lastName"
          onChange={handleChange}
          error={errors.lastName !== ''}
          helperText={errors.lastName}
        />
      </Stack>
      
      <div>
        <PhoneInput
          className="text-field min-w-300 m-10 "
          inputClass={`phone-input " ${errors.phone !== '' ? ' phone-input-error' : ''}`}
          preferredCountries={['il', 'us', 'gb', 'ca']}
          country={phone?.countryCode || 'il'}
          value={phone}
          onChange={handlePhoneChange}
          specialLabel=""
          error={errors.phone !== ''}
          helperText={errors.phone}
          autoFocus
          size="small"
        />
        {errors.phone !== '' ? (
          <div style={{ color: '#d32f2f', fontSize: 12, marginLeft: 20, marginBottom: 5 }}>{errors.phone}</div>
        ) : null}
      </div>

      <TextFieldForm
        id="city"
        placeholder="City"
        name="city"
        autoComplete="city"
        onChange={handleChange}
      />
      {hasState ? (
        <FormControl variant="outlined" className="text-field min-w-300 m-10">
          <InputLabel id="state-select-standard-label" style={{ background: '#fff', padding: '0 5px' }}>
            State
          </InputLabel>
          <Select
            labelId="state-select-standard-label"
            id="state-select"
            value={values.state}
            onChange={handleStateChange}
            name="state"
            size='small'
          >
            {states.map((state) => (
              <MenuItem key={state.name} value={state}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
      <div>
      <TextFieldForm
        id="email"
        name="email"
        value={values.email}
        disabled={true}
      />   
        <div className='f-size-10 mb-20 lh-1-5 ml-10' style={{padding:0, margin:0}}>
          Email will be used to send information about any updates<br/>regarding investment opportunities and deals
        </div>
      </div>   
      <FormControl className="text-field min-w-300 m-10">
        <Select
          labelId="country-select-standard-label"
          id="country-select"
          value={values.country}
          onChange={handleCountryChange}
          name="country"
          size="small"
        >
          {countries.map((country) => (
            <MenuItem key={country.name} value={country}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div
        style={{
          display:'flex',
          justifyContent: 'start',
          
        }} 
       
      >
        <div className='fdr-r'>
        <div className='checkbox-container'>
        <input type="checkbox"
              onChange={handleAccreditationChange}
              inputProps={{ 'aria-label': 'controlled' }}
             
              id="accreditation"
            />
           <label for="accreditation">{getAccreditationText()}</label>
           </div>

        <div className='checkbox-container'>
          <input type="checkbox"
            onChange={handleReceiveEmailChange}
            inputProps={{ 'aria-label': 'controlled' }}
            id="news"
            className=''
          />
          <label for="news">I agree to receive emails about new investment opportunities and important updates.
          <div className='f-size-9'>By checking this box, you’ll be the first to know about our latest insights and exclusive offerings.</div>
          </label>
          </div>
      </div>
    </div>
    </div>
  );
};

export default RegisterForm;
