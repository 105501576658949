import React from 'react';
import Stack from '@mui/material/Stack';

// iangels utils
import FounderWrapper from '../../components/FounderPage/FounderWrapper';
import TwoFieldStack from '../../components/FounderPage/TwoFieldStack';
import Field from '../../components/FounderPage/Field';
import Text from '../../components/text';
import { numbersOfFounders, numbersOfEmployees } from '../../helpers/constants';

const Team = ({ team, teamError, founders, foundersError, handleTeamChange, handleFounderChange, matches, title }) => {
  const numberOfFoundersField = {
    type: 'select',
    required: true,
    error: teamError.numberOfFounders,
    id: 'numberOfFounders',
    label: 'Number Of Founders *',
    labelId: 'NumberOfFoundersLabel',
    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
    value: team.numberOfFounders || '',
    itemsList: numbersOfFounders,
    name: 'numberOfFounders',
    onChange: handleTeamChange,
    helperText: teamError.numberOfFounders,
  };

  const numberOfEmployeesField = {
    type: 'select',
    required: false,
    error: teamError.numberOfEmployees,
    id: 'numberOfEmployees',
    label: 'Number Of Employees',
    labelId: 'numberOfEmployeesLabel',
    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
    value: team.numberOfEmployees || '',
    itemsList: numbersOfEmployees,
    name: 'numberOfEmployees',
    onChange: handleTeamChange,
    helperText: teamError.numberOfEmployees,
  };

  return (
    <FounderWrapper matches={matches}>
      <Text variant="h6" classes="f-tgf-400 g4-c" text={title} />
      <TwoFieldStack matches={matches} fieldAObject={numberOfFoundersField} fieldBObject={numberOfEmployeesField} />
      <>
        {founders
          ?.filter((f) => f.id < Number(team.numberOfFounders))
          .map((f) => (
            <div key={`founder${f.id}`} className="pt-15">
              <Text variant="h9" classes="f-tgf-400" text={`Founder #${f.id + 1}`} />
              <Stack direction={{xs:'column', sm: 'row'}} spacing={{ xs: 1, sm: 2, md: 4 }} alignItems="center" >
              <Field
                  matches={matches}
                  obj={{
                    type: 'text',
                    required: true,
                    error: foundersError[f.id]?.name,
                    id: 'foundername',
                    label: 'Name',
                    labelId: 'foundernameLabel',
                    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
                    style: { width: 'inherit', maxWidth: '100%', marginRight: matches ? 0 : 20, marginTop: 10 },
                    value: f.name || '',
                    name: 'founder[' + f.id + '].name',
                    onChange: handleFounderChange,
                    helperText: foundersError[f.id]?.name,
                  }}
                  style={{
                    width: 'inherit',
                    maxWidth: matches ? '100%' : '50%',
                    marginRight: matches ? 0 : 20,
                    marginTop: 10,
                  }}
                />
                <Field
                  obj={{
                    type: 'text',
                    required: true,
                    error: foundersError[f.id]?.title,
                    id: 'foundertitle',
                    label: 'Title/Role',
                    labelId: 'foundertitleLabel',
                    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
                    value: f.title || '',
                    name: 'founder[' + f.id + '].title',
                    onChange: handleFounderChange,
                    helperText: foundersError[f.id]?.title,
                  }}
                />
                <Field
                  obj={{
                    type: 'text',
                    required: true,
                    error: foundersError[f.id]?.email,
                    id: 'founderemail',
                    label: 'Email',
                    labelId: 'founderemailLabel',
                    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
                    value: f.email || '',
                    name: 'founder[' + f.id + '].email',
                    onChange: handleFounderChange,
                    helperText: foundersError[f.id]?.email,
                  }}
                />
                <Field
                  obj={{
                    type: 'text',
                    required: true,
                    error: foundersError[f.id]?.linkedin,
                    id: 'founderlinkedin',
                    label: 'Linkedin account',
                    labelId: 'founderlinkedinLabel',
                    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
                    value: f.linkedin || '',
                    name: 'founder[' + f.id + '].linkedin',
                    onChange: handleFounderChange,
                    helperText: foundersError[f.id]?.linkedin,
                  }}
                />
                <Field
                  obj={{
                    type: 'select',
                    required: true,
                    error: foundersError[f.id]?.fulltime,
                    id: 'founderfulltime',
                    label: 'Are you fulltime? *',
                    labelId: 'founderfulltimeLabel',
                    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
                    value: f.fulltime || '',
                    itemsList: ['Yes', 'No'],
                    name: 'founder[' + f.id + '].fulltime',
                    onChange: handleFounderChange,
                    helperText: foundersError[f.id]?.fulltime,
                  }}
                />
              </Stack>
            </div>
          ))}
      </>
    </FounderWrapper>
  );
};

export default Team;
