import React, {  useState,useEffect,useRef } from 'react';

// mui utils
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import {FormControl,OutlinedInput,InputAdornment} from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// iangels utils
import { getCloudinaryLogo } from '../../helpers/cloudinary';
import { getCommaSeparatedString } from '../../helpers/iangels';
import Text from '../../components/text';
import {getSectorImage } from '../../helpers/iangels';
import {updateRolloverValue,setPopup} from '../../containers/RolloverPopup/actions';
import { getSumEditableSelector } from '../../containers/RolloverPopup/selectors';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

// third party utils
import {  useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const OpportunityCard = ({ card}) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = IsMobileDevice();
  const sumEditable= !useSelector(getSumEditableSelector);
  const [amountToInvest, setAmountToInvest] = useState(card?.amountToInvest || 0); // Set initial state
  const inputRef = useRef(null); // Create a reference for the input

  const handleEditClick = () => {
    inputRef.current?.focus(); // Focus the input when EditOutlinedIcon is clicked
  };
  useEffect(() => {
    setAmountToInvest(card?.amountToInvest|| 0);
  }, [card?.amountToInvest]);

  const handleChange = (event) => {
    let value = event.target.value.replace(/,/g, ''); // Remove commas before processing
    if (isNaN(value) || value === '') {
      value=0;
     }
      setAmountToInvest(Number(value)); // Update state with a numeric value
      dispatch(updateRolloverValue(card.id,Number(value)));
  };

  const openStartup = (event) => {
    if(card.slugs[0])
    {
       dispatch(setPopup(false));
       navigate(`/startup/${card.slugs[0]}/rollover`);
    }
  };
  
  return (
    
     <div
      style={isMobile? { width: '100%', minWidth: 270 }
            : { width: '50%', minWidth: 270 }
      }
    >
      <Card style={{ margin: 10, padding: 0 }} className="card-company rollover-card-company">
        <CardActionArea href={''}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
        >
        
          <div style={{ width: '100%' }}>
            <div style={{ position: 'relative' }}>
              <CardMedia component="img" alt="green iguana" height="99" image={getSectorImage(card?.sector)} />
              <div style={{ position: 'absolute' }} className="sector g3-bg">
                {card?.sector}
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: -50,
                }}
              >
                <img src={getCloudinaryLogo(card?.logoStore, 80)} alt="logo" height={80} width={80} className="company-circle-logo" />
              </div>
            </div>

            <CardContent>
              <div className="card-content">
              <Text variant="h6" classes="f-tgf-400 f-size-16 g4-c" text={card?.name} />
              <br/>
              <Text variant="body2" classes="f-popup-content  g4-c" text={card?.tagLine} />
                { card?.shortDescription ? (
                  <Text variant="body2" classes="f-popup-content  g4-c" text={card?.shortDescription} />
                ) : null}
                 <div className="f-f-pmp f-w-500 italic f-size-12 lh-22 underline g3-c"
                   onClick={(event) => {
                    event.stopPropagation(); // Stop the click event from bubbling up to CardActionArea
                    openStartup();
                  }}
                  >
                  Read More
                  </div>
               
              </div>
            </CardContent>
          </div>
          <div style={{ width: '100%' }}>
           
          
            <CardActions >
              <FormControl fullWidth sx={{ m: 1 }} className="rollover-popup">
                <OutlinedInput
                  id="outlined-adornment-amount"
                  inputRef={inputRef}
                  startAdornment={<InputAdornment position="start" ><AttachMoneyIcon className="g3-c"/> </InputAdornment>}
                  endAdornment= {<InputAdornment position="end" >
                    {sumEditable? null : 
                    <EditOutlinedIcon
                     className="g3-c pointer" 
                     onClick={handleEditClick} />}
                     </InputAdornment>}
                  value={getCommaSeparatedString(parseFloat(amountToInvest.toFixed(2)))}
                  onChange={handleChange}
                  disabled={sumEditable}
                  className="f-f-gl f-size-14 lh-18  g4-c"
                  sx={{
                    height: "80%", // Make the input field 80% of its default height
                    "& .MuiOutlinedInput-input": {
                      padding: "8px 12px", // Adjust padding if needed
                      height: "80%", // Reduce inner height to match the outer shrink
                      fontWeight: "bold!important",
                    },                   
                  }}
                />
              </FormControl>
            </CardActions>
          </div>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default OpportunityCard;