import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

const Popup = ({icon, loading, title, textAccept, textDecline, onAccept, onDecline, closeIcon, backdropClasses, popupClasses, borderClasses}) => {
  const handleAccept = () => {
    onAccept();
  };

  const handleDecline = () => {
    onDecline();
  };

  return (
    <>
    {/* Backdrop  */}
    <div className={`backdrop ${backdropClasses}`} onClick={onDecline}></div>
    <div className={`popup ${popupClasses} ${borderClasses}`}>
    {loading ? <CircularProgress /> :
    <>
      {closeIcon && <CloseIcon onClick={onDecline} style={{ position: 'absolute', top: '10px', right: '10px', color:'gray' }}/>}   
      {icon && <div className="popup-icon">{icon}</div>}
      <h2 className='g4-c f-f-tgf f-w-500'>{title}</h2>
      {textAccept && <button onClick={handleAccept} className='w-c f-f-tgf f-size-12 f-w-500 g4-bg' style={{width:"80%"}}>{textAccept}</button>}
      <br/>
      {textDecline && <button onClick={handleDecline} className='g4-c f-f-g f-size-10 f-w-500 underline w-bg'>{textDecline}</button>}
    </>}
    </div>
  </>);
};

export default Popup;
