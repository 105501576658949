import React from 'react';

import Stack from '@mui/material/Stack';

// iangels utils
import FounderWrapper from '../../components/FounderPage/FounderWrapper';
import TwoFieldStack from '../../components/FounderPage/TwoFieldStack';
import Field from '../../components/FounderPage/Field';
import Text from '../../components/text';
import { numbersOfFundings, fundingStages, securityTypes } from '../../helpers/constants';

const PreviousFundings = ({
  previousFundings,
  previousRounds,
  previousRoundsError,
  handlePreviousFundingsChange,
  handlePreviousRoundsChange,
  matches,
  title,
}) => {
  const fundingsField = {
    type: 'select',
    required: true,
    id: 'fundings',
    label: 'Prevoius fundings',
    labelId: 'fundingsLabel',
    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
    value: previousFundings.fundings || 0,
    itemsList: numbersOfFundings,
    name: 'fundings',
    onChange: handlePreviousFundingsChange,
  };
  return (
    <FounderWrapper matches={matches}>
      <Text variant="h6" classes="f-tgf-400 g4-c" text={title} />
      <Field obj={fundingsField} />
      <>
        {previousRounds
          ?.filter((f) => f.id < Number(previousFundings.fundings))
          .map((f) => (
            <div key={`founder${f.id}`} className="pt-15">
              <Text variant="h9" classes="f-tgf-400" text={`Previous found ${f.id + 1}`} />
              <Stack direction={{xs:'column', sm: 'row'}} spacing={{ xs: 1, sm: 2, md: 4 }} alignItems="center" >
                <Field
                  obj={{
                    type: 'select',
                    required: true,
                    error: previousRoundsError[f.id]?.fundingStage,
                    id: 'fundingStage',
                    label: 'Funding stage *',
                    labelId: 'fundingStageLabel',
                    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
                    value: previousRounds[f.id]?.fundingStage,
                    itemsList: fundingStages,
                    name: 'round[' + f.id + '].fundingStage',
                    onChange: handlePreviousRoundsChange,
                    helperText: previousRoundsError[f.id]?.fundingStage,
                  }}
                />
                <Field
                  matches={matches}
                  obj={{
                    type: 'text-number',
                    required: true,
                    error: previousRoundsError[f.id]?.amountRaising,
                    id: 'amountRaising',
                    label: 'Amount raising (US$)',
                    value: previousRounds[f.id].amountRaising,
                    name: 'round[' + f.id + '].amountRaising',
                    onChange: handlePreviousRoundsChange,
                    helperText: previousRoundsError[f.id]?.amountRaising,
                  }}
                />
                <Field
                  matches={matches}
                  obj={{
                    type: 'select',
                    required: false,
                    id: 'dealType',
                    label: 'Security type',
                    labelStyle: { background: '#fff', top: '5px', left: '-12px' },
                    labelId: 'dealTypeLabel',
                    value: previousRounds[f.id].dealType,
                    itemsList: securityTypes,
                    name: 'round[' + f.id + '].dealType',
                    onChange: handlePreviousRoundsChange,
                  }}
                />{' '}
              </Stack>
              <TwoFieldStack
                matches={matches}
                fieldAObject={{
                  type: 'text-number',
                  required: false,
                  id: 'valuation',
                  label: 'Valuation (pre) US$',
                  value: previousRoundsError[f.id]?.valuation,
                  name: 'round[' + f.id + '].valuation',
                  onChange: handlePreviousRoundsChange,
                }}
                fieldBObject={{
                  type: 'date',
                  required: false,
                  id: 'date',
                  label: 'Round date',
                  value: previousRounds[f.id]?.date,
                  name: 'round[' + f.id + '].date',
                  onChange: handlePreviousRoundsChange,
                }}
              />
            </div>
          ))}
      </>
    </FounderWrapper>
  );
};

export default PreviousFundings;
