import React from 'react';

// mui utils
import UploadIcon from '@mui/icons-material/Upload';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';

// iangels utils
import VerifiedIcon from '../../assets/kyc-verified.svg';
import WarningIcon from '../../assets/kyc-warning.svg';

// third party utils
import { useNavigate } from 'react-router-dom';

const KycStatusTable = ({ kycStatus }) => {
  const navigate = useNavigate();

  const isSubmitted = (status) => {
    return status?.toLowerCase() === 'verified' || status?.toLowerCase() === 'submitted';
  };

  return (
    <Box>
      <Paper
        elevation={2}
        style={{
          height: '100%',
          borderRadius: 0,
          paddingBottom: 20,
          marginTop: 35,
          boxShadow: 'none',
        }}
      >
        <Table>
          <TableBody>
            {kycStatus?.map((row) => (
              <TableRow key={row.id} style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                <TableCell style={{ width: 10 }}>
                  {isSubmitted(row.status) ? (
                    <img src={VerifiedIcon} alt={row.status} loading="lazy" />
                  ) : (
                    <img src={WarningIcon} alt={row.status} loading="lazy" />
                  )}
                </TableCell>
                <TableCell className="f-tgf-400">{row.name}</TableCell>
                <TableCell
                  className={isSubmitted(row.status) ? 'f-tgf-400 uppercase g-c' : 'f-tgf-400 uppercase l-text'}
                >
                  {row?.status?.replace('_', ' ')}
                </TableCell>
                <TableCell className="f-gl-400 bold italic">{row.type?.replace('_', ' ')}</TableCell>
                {!isSubmitted(row.status) ? (
                  <TableCell>
                    <Button
                      variant="contained"
                      className="btn"
                      style={{ minWidth: 120, minHeight: 40, borderRadius: 0 }}
                      startIcon={<UploadIcon />}
                      onClick={() => {
                        navigate(`/entity/upload/${row.id}`);
                      }}
                    >
                      Upload Forms
                    </Button>
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default KycStatusTable;
