import React, { useCallback, useState } from 'react';
import moment from 'moment';

//mui utils
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Grid from '@mui/material/Grid';

//helpers
import { INVESTMENTS_SIDE_NAV } from '../../helpers/constants';
import { track } from '../../services/analytics';

// iangels utils
import FinancialsAndTraction from '../../components/FinancialsAndTraction';
import CapitalPosition from '../../components/CapitalPosition';
import MemoCompanyUpdate from './MemoCompanyUpdate';
import MemoWrapper from './MemoWrapper';
import MemoSubTitle from './MemoSubTitle';

const MemoCompanySnapshot = ({
  keyBusinessUpdates,
  monthlyNetBurnRate,
  operatingCashFlow,
  snapshotIsVisible,
  isUserVerified,
  cashOnHand,
  updates,
  kpis,
  monthlySnapshotIsAvailable,
  defaultPeriod,
}) => {
  // state
  const [keyBusinessUpdatesCount, setKeyBusinessUpdatesCount] = useState(1);

  const hasSnapshot = () => {
    return keyBusinessUpdates?.length > 0 && snapshotIsVisible && isUserVerified;
  };

  const hasUpdates = () => {
    return updates?.length > 0 && !snapshotIsVisible;
  };

  const showMore = useCallback(() => {
    setKeyBusinessUpdatesCount(keyBusinessUpdatesCount + 3);
    track('Show More', { feature: 'snapshot', section: 'Key Business Updates' });
  }, [keyBusinessUpdatesCount]);

  const showMoreButton = () => {
    return keyBusinessUpdates?.length > keyBusinessUpdatesCount;
  };
  const hasCapitalPosition = () => {
    return cashOnHand || monthlyNetBurnRate || operatingCashFlow;
  };

  return (
    <MemoWrapper id={INVESTMENTS_SIDE_NAV.companySnapshot} title={INVESTMENTS_SIDE_NAV.companySnapshot}>
      {hasSnapshot() ? (
        <>
          <Grid container spacing={2}>
            {keyBusinessUpdates?.length > 0 ? (
              <Grid item xs={8}>
                <MemoSubTitle title="Key business updates" />
                {keyBusinessUpdates.map((businessUpdates, index) =>
                  index < keyBusinessUpdatesCount ? (
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }} key={index} className="pb-20">
                      <Grid container spacing={0} key={index}>
                        <Grid item xs={2} className="f-f-gl f-w-700 f-size-14 nowrap">
                          {moment(businessUpdates?.date).format('DD MMM YYYY')}
                        </Grid>
                        <Grid item xs={10} className="f-f-g">
                          {businessUpdates?.text}
                        </Grid>
                      </Grid>
                    </Grow>
                  ) : null
                )}
                {showMoreButton() ? (
                  <Button
                    variant="outlined"
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={showMore}
                    className="btn-outlined-show mr-15 ml-20 mt-10 f-f-g"
                  >
                    Show More
                  </Button>
                ) : null}
              </Grid>
            ) : null}

            {/*  Capital Position*/}
            {hasCapitalPosition() ? (
              <Grid item xs={4}>
                <div style={{ marginTop: 10, marginRight: 10 }}>
                  <CapitalPosition
                    cashOnHand={cashOnHand}
                    monthlyNetBurnRate={monthlyNetBurnRate}
                    operatingCashFlow={operatingCashFlow}
                  />
                </div>
              </Grid>
            ) : null}
          </Grid>

          {/* Financials And Traction */}
          {kpis?.length > 0 ? (
            <MemoWrapper id="Financials and Traction" subTitle="Financials and Traction" classes="mt-20">
              <FinancialsAndTraction
                kpis={kpis}
                monthlySnapshotIsAvailable={monthlySnapshotIsAvailable}
                defaultPeriod={defaultPeriod}
              />
            </MemoWrapper>
          ) : null}
        </>
      ) : null}
      {hasUpdates()
        ? updates.map((update, index) => (
            <MemoCompanyUpdate title={update.title} content={update.content} isFirst={index === 0} key={index} />
          ))
        : null}
    </MemoWrapper>
  );
};

export default MemoCompanySnapshot;
