import React from 'react';

// mui utils
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';

// third party utils
import { useSelector } from 'react-redux';

// iAngels utils
import { PROVIDER_TYPES } from '../../helpers/constants';
import { getUserSelector  } from '../App/selectors';
import Congrats from '../../assets/congrats.svg';
import Text from '../../components/text';

const RegisterComplete = ({unsubscribe, setSubscribe, isMobile}) => {
  const user = useSelector(getUserSelector);
  const providerType = () => {
    if (!user?.identities) return null;
    return user.identities[0].connection;
  };

  const providerTypeName = providerType();

  const handleSubscribe = (event) => {
    setSubscribe(event.target.checked);
  };

  return (
    <div direction='column' className="f-g-400 g4-c f-size-14" 
    style={{justifyContent:'center' , display:'flex', flexWrap:'wrap'}}>
    <Stack direction="column" spacing={2} 
      sx={{ padding:'20px 40px 10px 40px' }}>
      {isMobile && <div align="center"><img src={Congrats} alt="congrats" width="80px" height="80px"/></div>}
      <Stack direction={(isMobile || unsubscribe === false) ? "column" : "row"}  spacing={4}>
        <Stack direction="column" className={`${isMobile || unsubscribe===false ? 'center' : 'justify'}`}>
          <Text classes={`f-f-tgf f-w-500 g4-c ${isMobile ? 'f-size-18 mb-10 ' : 'f-size-22 mb-20'} mt-10`} text="Thank you for registering!" />
           {!isMobile && unsubscribe === false && <div align="center"><img src={Congrats} alt="congrats" width="120px" height="120px"/></div>}
          <Text classes={`block f-f-g g4-c ${isMobile ? 'f-size-14' : 'f-size-16'} ${unsubscribe === false ? ' f-w-700' : ' f-w-400'}`}
            text={
              providerTypeName === PROVIDER_TYPES.UPA ||
              providerTypeName === PROVIDER_TYPES.UPA_STAGING ||
              providerTypeName === PROVIDER_TYPES.U_NAME_PASS
                ? 'An activation link was sent to your email address.' : ''}
          />
          <Text
            classes={`block f-f-g f-w-400 g4-c ${isMobile ? 'f-size-14' : 'f-size-16'}`}
            text={
              providerTypeName === PROVIDER_TYPES.UPA ||
              providerTypeName === PROVIDER_TYPES.UPA_STAGING ||
              providerTypeName === PROVIDER_TYPES.U_NAME_PASS
                ? 
                  'Please confirm your account to access investment opportunities.'
                : "Welcome to the iAngels' investment platform. Feel free to explore investment opportunities by clicking explore."
            }
            afterClasses={'block pt-15'}
            afterText = {          
              (providerTypeName === PROVIDER_TYPES.UPA ||
              providerTypeName === PROVIDER_TYPES.UPA_STAGING ||
              providerTypeName === PROVIDER_TYPES.U_NAME_PASS) ?
              "Check spam/promotions if you don’t see it at first, and remember to mark as 'not spam'."
               : ''
              }
          />
        </Stack>
        {!isMobile && unsubscribe === true && <img src={Congrats} alt="congrats" width="142px" height="145px"/>}
      </Stack>
      {unsubscribe === true ? 
          <>
            <hr className='mt-30 mb-30' />
            <div className='g4-c lh-2'>
              <div className='f-size-14 f-w-500 f-f-tgf'>New Deal Emails</div>
              <Text classes={`block pt-15 f-f-g f-w-400  g4-c mb-10 ${isMobile ? 'f-size-14 left' : 'f-size-16 justify'}`}
                text="Please note due to your current settings, you will not receive investment opportunities."
              /> 
              <span className={`block -w-500 f-f-tgf ${isMobile ? 'f-size-12' : 'f-size-14'} `}>             
                <Checkbox onClick={handleSubscribe} inputProps={{ 'aria-label': 'controlled' }} />
                I would like to receive new investment opportunities
              </span>
            </div>
          </> : null}      
    </Stack>
    </div>
  );
};

export default RegisterComplete;
