import React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import KYCSelection from './KYCSelection';
import BalanceSection from './BalanceSection';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

const HeaderPopup = ({cashBalances,entitiesList,rolloverStartupFrom, onSelectChange, selected  }) => {
  const isMobile = IsMobileDevice();
  const reinvetsText= isMobile? "Reinvest: ":"Reinvest from: ";
  return (
    <Container>
       <Stack
        width="inherit"
        className={isMobile ?"f-popup-header-content-mobile": "f-popup-header-content"}
        sx={{
          position: "relative", // Allows precise placement of the icon
        }}
      >
        <span className="w-c" style={{ textAlign: "left" }}>
         {reinvetsText} <span className="f-f-pmp italic">{rolloverStartupFrom}</span>
        </span>

      </Stack>
      <Stack width={'inherit'} direction={isMobile ? 'column' : 'row'} sx={{ marginTop: 1 }} >
        <KYCSelection entities={entitiesList} onSelectChange={onSelectChange} selected={selected}/>
      </Stack>
      <Stack width={'inherit'} direction={isMobile ? 'column' : 'row'} >
       <BalanceSection cashBalances={cashBalances} />
       </Stack>
    </Container>
    
  );
};

export default HeaderPopup;