import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

// third party utils
import { useSelector } from 'react-redux';

// iAngels utils
import { getCommaSeparatedString } from '../../helpers/iangels';
import { getTotalInvestmentsSelector, getRolloversToInvestSelector } from '../../containers/RolloverPopup/selectors';

const FooterPopup = ({ sumRollover, sumCashBalance, sumOther, onInvest }) => {
  const isMobile = IsMobileDevice();
  const totalInvestments = useSelector(getTotalInvestmentsSelector);
  const rolloversToInvest = useSelector(getRolloversToInvestSelector);
  return (
    <Grid container className="rollover-footer">
      <Grid
        item
        xs={6}
        sx={
          sumCashBalance > 0 || sumOther > 0
            ? isMobile
              ? {}
              : { marginBottom: 1 }
            : { borderBottom: 1, borderColor: '#E1E1E1', paddingBottom: 1, marginBottom: isMobile ? 0 : 1 }
        }
        className="f-popup-sum"
      >
        Roll over amount:
      </Grid>
      <Grid
        item
        xs={6}
        sx={
          sumCashBalance > 0 || sumOther > 0
            ? { marginBottom: isMobile ? 0 : 1, textAlign: 'right' }
            : {
                textAlign: 'right',
                borderBottom: 1,
                borderColor: '#E1E1E1',
                paddingBottom: 1,
                marginBottom: isMobile ? 0 : 1,
              }
        }
        className="f-popup-sum "
      >
        {'$ ' + getCommaSeparatedString(sumRollover)}
      </Grid>
      {sumCashBalance > 0 ? (
        <>
          <Grid
            item
            xs={6}
            sx={sumOther > 0 ? {} : { borderBottom: 1, borderColor: '#E1E1E1', paddingBottom: 1, marginBottom: 1 }}
            className="f-popup-content g4-c pt-0"
          >
            Cash balance:
          </Grid>{' '}
          <Grid
            item
            xs={6}
            sx={
              sumOther > 0
                ? { textAlign: 'right' }
                : { textAlign: 'right', borderBottom: 1, borderColor: '#E1E1E1', paddingBottom: 1, marginBottom: 1 }
            }
            className="f-popup-content g4-c pt-0"
          >
            {'$ ' + getCommaSeparatedString(sumCashBalance)}
          </Grid>{' '}
        </>
      ) : null}
      {sumOther > 0 ? (
        <>
          <Grid
            item
            xs={6}
            sx={{ borderBottom: 1, borderColor: '#E1E1E1', paddingBottom: 1, marginBottom: 1 }}
            className="f-popup-content g4-c pt-0 "
          >
            Extra investment:
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: 'right', borderBottom: 1, borderColor: '#E1E1E1', paddingBottom: 1, marginBottom: 1 }}
            className="f-popup-content g4-c pt-0"
          >
            {'$ ' + getCommaSeparatedString(sumOther)}
          </Grid>{' '}
        </>
      ) : null}

      <Grid item xs={6} className="f-popup-sum  pt-0">
        Total reinvestment:
      </Grid>
      <Grid item xs={6} sx={{ textAlign: 'right' }} className="f-popup-sum  pt-0">
        {'$ ' + getCommaSeparatedString(totalInvestments)}
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 1 }}>
        <Button
          variant="contained"
          className="popup-btn-invest"
          style={{ width: '100%', height: '50px' }}
          onClick={onInvest}
          disabled={rolloversToInvest && rolloversToInvest.length > 0}
        >
          Invest
        </Button>
      </Grid>
    </Grid>
  );
};

export default FooterPopup;
