import React, { useEffect, useCallback, useState } from 'react';

// mui utils
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

// iangels utils
import { entityDocuments } from '../../helpers/constants';
import { track } from '../../services/analytics';
import KycDocumentsItem from './KycDocumentsItem';
import IsMobileDevice from '../Generic/IsMobileDevice';

import Text from '../text';

import {
  getKycFilesSelector,
  getSavedFilesSuccessSelector,
  getFinishInvestmentSuccessSelector,
  getKycSubmittedDocumentsErrorSelector,
} from '../../containers/KycSubmitDocuments/selectors';

import {
  saveDocumentsAttempt,
  finishInvestmentAttempt,
  clearKycSubmittedDocuments,
  clearFiles,
} from '../../containers/KycSubmitDocuments/actions';

// third party utils
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

const KycSubmitDocuments = ({ kyc, errors, setErrors, isInvestmentFlow }) => {
  const isMobile = IsMobileDevice();

  const [loading, setLoading] = useState(false);
  let missingDocs = [];

  const currentlyUploadingFiles = useSelector(getKycFilesSelector);
  const savedFilesSuccess = useSelector(getSavedFilesSuccessSelector);
  const savedFilesFailure = useSelector(getKycSubmittedDocumentsErrorSelector);
  const finishInvestmentSuccess = useSelector(getFinishInvestmentSuccessSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { investmentType, investmentId } = useParams();

  useEffect(() => {
    if (savedFilesFailure) {
      dispatch(clearKycSubmittedDocuments());
      navigate('/error');
    }
    if (savedFilesSuccess) {
      dispatch(clearKycSubmittedDocuments());
      navigate('/entity/verification');
    }
    if (finishInvestmentSuccess) {
      dispatch(clearKycSubmittedDocuments());
      navigate(`/invest/${investmentType}/${investmentId}/thank-you`);
    }
    dispatch(clearFiles());
  }, [savedFilesFailure, savedFilesSuccess, finishInvestmentSuccess, investmentType, investmentId, navigate, dispatch]);

  const handleBackOfficeClick = () => {
    window.location.href = 'mailto:backoffice@iangels.com';
  };

  const kycDocuments = useCallback(() => {
    let arr = kyc?.howYouInvest ? entityDocuments['upload'][kyc?.howYouInvest] : null;
    arr = arr.filter((item) => {
      if (item.fieldDependency) {
        const dependencies = item.fieldDependency ? item.fieldDependency.split(',') : [];
        const hiddenFor = item.hiddenFor ? item.hiddenFor.split(',').map(h => h.trim()) : [];
        const displayFor = item.displayFor ? item.displayFor.split(',').map(d => d.trim()) : [];

        return dependencies.every((dep, index) => 
          kyc[dep] &&
          (!hiddenFor[index] || !hiddenFor[index].split(' or ').includes(kyc[dep])) &&
          (!displayFor[index] || displayFor[index].split(' or ').includes(kyc[dep]))
        );
      }
      return true;
    });
    return arr;
  }, [kyc]);

  const isResidencyValid = (currentErrors) => {
    if (!kyc.residency) {
      currentErrors['residency'] = 'Residency is a mandatory field.';
      return false;
    }
    return true;
  };

  const isTaxResidencyValid = (currentErrors) => {
    if (!kyc.taxResidency) {
      currentErrors['taxResidency'] = 'Tax Residency is a mandatory field.';
      return false;
    }
    return true;
  };  
  
  const isTaxResidencyOtherValid = (currentErrors) => {
    if (!kyc.taxResidencyOther && kyc.residency === 'other') {
      currentErrors['taxResidencyOther'] = 'Country of your residency is a mandatory field.';
      return false;
    }
    return true;
  };

  const handleSave = () => {
    let currentErrors = { ...errors };
    const residencyValid = isResidencyValid(currentErrors);
    const taxResidencyValid = isTaxResidencyValid(currentErrors);
    const taxResidencyOtherValid = isTaxResidencyOtherValid(currentErrors);

    if (!residencyValid || !taxResidencyValid || !taxResidencyOtherValid) {
      currentErrors['requiredFieldsError'] = 'Please fill in all required fields';
      setErrors(currentErrors);
      return;
    }
    currentErrors['requiredFieldsError'] = '';

    setLoading(true);

    let data = {};

    _.forEach(kycDocuments(), function (file) {
      if (currentlyUploadingFiles === undefined ||
         Object.keys(currentlyUploadingFiles).length === 0 ||
         (currentlyUploadingFiles[file.apiKey] === undefined &&
            (kyc?.documents === undefined ||
              kyc?.documents[file.apiKey] === undefined ||
              kyc?.documents[file.apiKey] === null
            )
          ))
          missingDocs.push(file.missingDesc);
    });

    data.name = kyc.name;
    data.address = kyc.address;
    data.howYouInvest = kyc.howYouInvest;
    data.whatTypeOfLegalEntity = kyc.whatTypeOfLegalEntity;
    data.status = missingDocs.length === 0 ? 'submitted' : 'partially_submitted';
    data.residency = kyc.residency;
    data.taxResidency = kyc.taxResidency;
    data.taxResidencyOther = kyc.taxResidencyOther;
    data.missingDocs = missingDocs;
    
    Object.assign(data, currentlyUploadingFiles);
    dispatch(
      isInvestmentFlow ? finishInvestmentAttempt(data, investmentType, investmentId) : saveDocumentsAttempt(data)
    );
    track('KYC Uploader Submission', {
      entity_name: kyc.name,
      entity_type: kyc.howYouInvest,
    });
  };

  const getDownloadName = (download) => {
    return download
      ? download?.field
        ? download.replace
          ? download.all.replace('X' + download.field + 'X', kyc[download.field])
          : download[kyc[download.field]]
          ? download[kyc[download.field]]
          : download.all
        : download.all
      : null;
  };

  return (
    <>
      <Text text="*Please upload PDF or image files." classes="f-gl-400 f-size-12 italic mt-5" />
      <div className="d-f fdr-c">
        {kycDocuments().map((doc, index) => (
          <KycDocumentsItem
            key={index}
            {...doc}
            approved={kyc?.documents ? kyc?.documents[doc.apiKey] : ''}
            downloadKey={getDownloadName(doc.download)}
          />
        ))}
        <div
          className="mt-30"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <Text
            classes="f-gl-400 italic f-size-14"
            style={{ marginTop: 10, marginRight: 15 }}
            variant="body2"
            text="If you have any questions, please contact our compliance officer at "
            extraText="backoffice@iangels.com"
            handleClick={handleBackOfficeClick}
            extraClasses="g-c bold pointer"
          />
          <div
            style={{
              marginTop: isMobile ? 25 : 0,
              marginBottom: isMobile ? 25 : 0,
              minWidth: isMobile ? 0 : 400,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            {loading ? (
              <CircularProgress size={20} thickness={4} className="g-c ml-10" />
            ) : (
              <Stack direction="column">
                <Button
                  className="btn card-btn"
                  style={{ minWidth: 220, minHeight: 40, borderRadius: 6 }}
                  onClick={handleSave}
                >
                  Submit for Approval
                </Button>
                <Text
                  classes="r-c mt-5 df-c"
                  text={errors['taxResidency'] || errors['taxResidencyOther'] || errors['residency'] ? errors.requiredFieldsError : ''}
                />
              </Stack>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default KycSubmitDocuments;
