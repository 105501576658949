import React from 'react';

// mui utils
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { track } from '../../services/analytics';

// third party utils
import { useNavigate } from 'react-router-dom';
const ModeBanner = ({
  showModeBanner,
  isPreviewer,
  isImpersonated,
  isAdmin,
  isGuest,
  isIncompleteKyc,
  impersonatorEmail,
  setShowModeBanner,
}) => {
  const getBannerText = () => {

    if (isIncompleteKyc) {
      return (
        <span>
          Your KYC is incomplete. Please upload the missing documents in the <a href="/entity/verification" style={{color: 'blue'}}>My KYC</a> section.
        </span>
      );
    }
    
    if (isGuest) {
      return (
        <Stack direction="row" spacing={4}>
          <div>You are currently in limited viewing mode. Login to access the full investment memo.</div>
          <Button
            variant="outlined"
            className="btn-banner"
            onClick={() => {
              track('Flash Message Click', { action: 'Log In' });
              navigate('/');
            }}
          >
            LOG IN
          </Button>
        </Stack>
      );
    }

    if (isPreviewer) {
      return (
        <span>
          You're a <strong className="g-c">previewer</strong>, you may see deals that are not publicly available right
          now.
        </span>
      );
    }

    if (isImpersonated) {
      return (
        <span style={{ color: '#000' }}>
          <strong className="g-c">{impersonatorEmail}</strong> you're
          <strong className="g4-c"> impersonating</strong>, be advised you see private information.
        </span>
      );
    }

    if (isAdmin) {
      return (
        <span>
          You're an <strong className="g4-c">admin</strong>, you may see deals that are not publicly available right
          now.
        </span>
      );
    }
  };
  const navigate = useNavigate();
  return isPreviewer || isImpersonated || isAdmin || isGuest ? (
    <Collapse in={showModeBanner}>
      <Alert
        icon={false}
        className={
          'mode-banner' +
          (isPreviewer
            ? ' preview-mode-banner'
            : isImpersonated
            ? ' impersonation-mode-banner'
            : isAdmin
            ? ' admin-mode-banner'
            : ' guest-mode-banner')
        }
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setShowModeBanner(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {getBannerText()}
      </Alert>
    </Collapse>
  ) : null;
};

export default ModeBanner;
