export const IDENTITY_REGISTER_ATTEMPT = 'IDENTITY_REGISTER_ATTEMPT';
export const IDENTITY_REGISTER_SUCCESS = 'IDENTITY_REGISTER_SUCCESS';
export const IDENTITY_REGISTER_FAILURE = 'IDENTITY_REGISTER_FAILURE';

export const IDENTITY_SUBSCRIBE_ATTEMPT = 'IDENTITY_SUBSCRIBE_ATTEMPT';
export const IDENTITY_SUBSCRIBE_SUCCESS = 'IDENTITY_SUBSCRIBE_SUCCESS';
export const IDENTITY_SUBSCRIBE_FAILURE = 'IDENTITY_SUBSCRIBE_FAILURE';

export const CLEAR_SIGNUP_STATE = 'CLEAR_SIGNUP_STATE';

export const CLEAR_STATE = 'CLEAR_STATE';
