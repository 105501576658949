import React from 'react';
import OpportunityCard from './OpportunityCard';
import { Stack } from '@mui/material';
const OpportunitiesSection = ({rollovers}) => {
  return (
  <>
      <span className="g4-c f-f-tgf f-w-500 f-size-16 lh-16 ml-10">Suggested opportunities</span>
        <Stack  direction="row" 
          sx={{
            flexWrap: "wrap",
            justifyContent: rollovers?.length>1 ? "space-between":"center", // Ensures equal spacing
          }}>
          {rollovers?.map((opportunity, index) => (
            <OpportunityCard key={index} card={opportunity} />
          ))}
          </Stack>
  </>
  );
};

export default OpportunitiesSection;